// -------------------
// orglandingPage.js |
// -------------------
// Serves as the landing page for when a user visits the org URL or scans a QR code. It is a cosmetic landing page intended to stir action
// and prepare users for the coming check-out process. 
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import {  useLocation, useNavigate, Navigate, Outlet, useOutletContext} from "react-router-dom";
import axios from 'axios';
const { DateTime } = require("luxon");
import DOMPurify from 'dompurify';

// COMPONENTS
// ========================================
import Loader from '../sub-components/loader.jsx'
import Footer from "../sub-components/footer.jsx"
import BannerImage from "../sub-components/bannerImage.jsx"
import HeaderImage from '../sub-components/HeaderImage.jsx'
import GoalTracker from "../sub-components/goalTracker.jsx"
import HolidayButton from '../sub-components/holidayButton.jsx'

// ICONS
// ========================================
import icon from '../../assets/mosque.svg'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  {faMoon } from '@fortawesome/free-solid-svg-icons'
import  {faSchool } from '@fortawesome/free-solid-svg-icons'

import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'


// LOADER COMPONENT
// ========================================

function OrgLandingPage(params){

	// Context Data for retrieving the org data, session data, and load status
	const outletContext = useOutletContext();
  	const sessionID = outletContext.sessionID;
  	const setsessionID = outletContext.setSessionID;
  	const serverURL = outletContext.serverURL;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
  	var isLoading = outletContext.isLoading;
  	var successfulFetch = outletContext.successfulFetch;
	var setSubscriptionDonation = outletContext.setSubscriptionDonation
	var setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	var setSubscriptionCount = outletContext.setSubscriptionCount;
	var setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	var setSpecifiedCause = outletContext.setSpecifiedCause;
	var setCurrentDonation = outletContext.setCurrentDonation;
	var isHolidayActive = outletContext.isHolidayActive;
	var setIsHolidayActive = outletContext.setIsHolidayActive;
	var schoolEnrollmentActive = outletContext.schoolEnrollmentActive;
	var schoolName = outletContext.schoolName;

  	// Check size of display
  	const [ isOrgNameLong, setisOrgNameLong ] = useState(false);
	const [isFriday, setIsFriday] = useState(false);

  	// React router navigation hook
	let navigate = useNavigate();
	let location = useLocation();

	// Side Effect for scrolling to the bottom of the page to show the "Begin Donating" button by default. 
	useEffect(() => {

		var element = document.getElementById("scrollspot");
		element.scrollIntoView();

	})

	// Check size of the org title
	useEffect(() => {

		if (orgData.account_info != null && orgData.account_info.display_name.length > 30) {
			setisOrgNameLong(true)
		}

	}, [location, orgData])

	// Check if its Jummah 
	useEffect(() => {
		
		var today = DateTime.local().weekday;
		if (today == 5 || today == 4 || today == 6) {
			setIsFriday(true);
		}
		else {
			setIsFriday(false);
		}
	
	}, []);

	// Handle user descriptions with links
	function renderUserInput(input) {
		const sanitizedInput = DOMPurify.sanitize(input, { ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href'] });
		const html = `<div>${sanitizedInput}</div>`;
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	  }

	// Render Content

	// If the app has yet to pull the org data, display the loader.
	if(isLoading == true) {
		return (
			<div className="h-full min-h-screen lg:min-h-0 flex flex-col 3xl:hidden">

				<div className="flex-grow lg:flex-grow-0">
					<div className = " ">
						<Loader className=""/>
					</div>
			 	</div>

		    	{/*Page Footer*/}				
		    	<hr id="scrollspot" className="mt-4 mb-2 w-11/12 3xl:w-0 mx-auto"/>
				{/* <Footer/> */}
			</div>

		);
	}

	// Once the data has loaded, display the landing page
	else {

		return (

			<div id="scrollspot" className="flex flex-col">
				
				{/* Mobile Version */}
				<div className="">

					{/* Mobile Displays - Banner */}
					<div className="xl:hidden">
						<BannerImage source={orgData.account_theme.banner_image} server={serverURL} />
					</div>

					{/* Small Desktop Displays - Banner */}
					<div className="hidden xs:hidden sm:hidden md:hidden lg:hidden xl:block 2xl:block 3xl:hidden">
						<HeaderImage source={orgData.account_theme.header_image} server={serverURL} />
					</div>

				</div>
				
				<div className="mt-3 flex-grow">

					{/* Donate To */}
					<div className="flex 3xl:pt-3">
						<h1 className = {`3xl:pb-2 ${isOrgNameLong ? "text-xl 3xl:text-3xl" : "text-2xl 3xl:text-3xl" } ml-6`}>Donate to</h1>
						<img className = "h-9 ml-2" src={icon} alt="Icon" />
					</div>

					{/* Org Name */}
					<h1 className = {`3xl:mb-4 ${isOrgNameLong ? "text-xl 3xl:text-3xl" : "text-2xl 3xl:text-3xl" } font-bold mb-1 pt-1 ml-6`}>{orgData.account_info.display_name}</h1>

					{/* Goal Tracker */}
					<GoalTracker left={false} title={false} orgID={orgID} orgType={orgData.account_info.org_type} featureEnabled={orgData.account_features.goal_tracker}/>

					{/* Dividers */}
					<hr className = "mt-2 mb-1 w-11/12 mx-auto" />

					{/*Primary Donation Button + Islamic Button */}
					<div className="w-11/12 mx-auto">

						{/* Primary Button */}
						<button 
							onClick={() => { 
								setSubscriptionDonation(false);
								setToggleSubscriptionCount(false);
								setCurrentDonation(0)
								setSpecifiedCause({})
								navigate("../Set-Donation"); 
								
							}}

							id="beginBtn" className="mt-2 rounded-md text-center text-white opacity-100 bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-xl py-3 py-4 font-bold w-full shadow-md shadow-slate-400 leading-3 3xl:mb-1 3xl:mt-3"> Donate Now<span className="text-xl"><FontAwesomeIcon className="pl-3" icon={faCircleArrowRight}/></span><br/> <span className="text-sm font-light opacity-50 italic">Contribute Where Needed Most</span> 
						</button>

					{/* Islamic Button */}
					<div className="3xl:mb-1">
						{/* Islamic Holiday Button */}
						<HolidayButton isHolidayActive = {isHolidayActive} setIsHolidayActive = {setIsHolidayActive} setSubscriptionDonation = {setSubscriptionDonation} setSubscriptionFrequency = {setSubscriptionFrequency} setToggleSubscriptionCount= {setToggleSubscriptionCount} setSubscriptionCount = {setSubscriptionCount} setCurrentDonation = {setCurrentDonation} setSpecifiedCause = {setSpecifiedCause} holidayFeatureEnabled = {orgData.account_features.islamic_holidays}/>
					</div>

					{/* Weekly Jummah Donation Button */}
					<button 
						onClick={() => { 
							setSubscriptionDonation(true);
							setToggleSubscriptionCount(true);
							setCurrentDonation(10);
							setSubscriptionFrequency("week");
							setSubscriptionCount("until stopped");
							setSpecifiedCause({title: "Jummah Pledge", pathname: "jummah-pledge", goalInclusion: true});
							navigate("../Set-Donation"); 	
						}}

						id="beginBtn" className={` 	mt-2 rounded-md text-center text-stone-700 opacity-100 text-xl py-3 py-4 font-bold w-full shadow-md shadow-slate-400 leading-3 3xl:mb-1 3xl:mt-3 ${isFriday ? "block " : "hidden"} ${isHolidayActive ? "hidden" : " bg-amber-300 hover:bg-amber-400 active:bg-amber-400 "}   `}> Jummah Pledge<span className="text-xl"><FontAwesomeIcon className="pl-2" icon={faClockRotateLeft}/></span><br/> <span className="text-sm font-light opacity-50 italic">Automate a Weekly Jummah Donation</span> 
					</button>


					</div> 

					{/*Islamic School Page*/}
					<div className={`${orgData.account_features.islamic_school && schoolEnrollmentActive ? "" : "hidden"}`} >
						<div className="w-11/12 mx-auto ">

							<button 
								onClick={() => { 
			          				navigate("../Islamic-School"); 
			          			}}

								id="specifyBtn" className="mt-3 rounded-md
								text-center text-slate-800 opacity-100 border
								bg-fuchsia-200 hover:bg-fuchsia-300
								active:bg-blue-200 
								hover:text-black py-3  w-full
								mr-2 shadow-md 3xl:mb-1 font-semibold text-lg 3xl:text-xl 3xl:font-semibold 3xl:py-3 leading-3"> {schoolName} Registration  <span className="text-xl"><FontAwesomeIcon className="pl-2 pt-1" icon={faSchool}/></span><br/> <span className="text-sm font-light opacity-50 italic">Sign Up for the Upcoming Islamic School Program</span> 
							</button> 
						</div> 
					</div>

					{/*Designation Donation Page*/}
					<div className={`${orgData.account_features.specify_cause ? "" : "hidden"}`} >
						<div className="w-11/12 mx-auto ">

							<button 
								onClick={() => { 
			          				navigate("../Specify-Cause"); 
			          			}}

								id="specifyBtn" className="mt-3 rounded-md
								text-center text-slate-800 opacity-100 border
								bg-slate-200 hover:bg-blue-200
								active:bg-blue-200 
								hover:text-black py-3  w-full
								mr-2 shadow-md 3xl:mb-1 font-semibold text-lg 3xl:text-xl 3xl:font-semibold 3xl:py-3 leading-3"> Choose a a Specific Cause  <span className="text-xl"><FontAwesomeIcon className="pl-2 pt-1" icon={faCircleCheck}/></span><br/> <span className="text-sm font-light opacity-50 italic">Designate Where You'd Like to Donate</span> 
							</button> 
						</div> 
					</div>




					{/* Divider */}
					<hr className = "mb-5 mt-3 w-11/12 mx-auto" />

					{/* Account Messages */}
					<div className="text-neutral-600 ">
						<div className="mx-auto ml-7 mr-2"><p className = "text-sm 3xl:text-base font-bold leading-6">{orgData.account_message.title}</p></div>
						<div className="mx-auto ml-7 pr-3"><div className = "text-sm 3xl:text-base leading-6 3xl:leading-8 3xl:mr-2">{renderUserInput(orgData.account_message.subtitle)}</div></div>
					</div>


				</div>

		    	{/*Page Footer*/}	
				{/* <hr className="mt-4 mb-2 w-11/12 mx-auto"/> */}

				<Footer/>

			</div>
		)

	}

	
}


// Export Component
export default OrgLandingPage;



