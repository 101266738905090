// --------------------
// setDonationPage.js |
// --------------------
// Page controls the initial donation page. Controls both currentDonation and addDonation which set the initial values. 
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { useOutletContext, useParams, useLocation } from "react-router-dom";
import axios from 'axios';

// COMPONENTS
// ========================================
import CurrentDonation from '../sub-components/currentDonation.jsx'
import AddDonation from '../sub-components/addDonation.jsx'
import RecurringDonation from '../sub-components/recurringDonations.jsx';

// DONATION PAGE
// ========================================
function DonationPage(){

	// Context Data for tracking the session and server interactions 
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const currentDonation = outletContext.currentDonation;
	const setCurrentDonation = outletContext.setCurrentDonation;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
   	const dbsClicked = outletContext.dbsClicked;
  	const setdbsClicked = outletContext.setdbsClicked;
  	const specifiedCause = outletContext.specifiedCause;
  	const setSpecifiedCause = outletContext.setSpecifiedCause;
	const subscriptionDonation = outletContext.subscriptionDonation;
	const setSubscriptionDonation = outletContext.setSubscriptionDonation;
	const subscriptionFrequency = outletContext.subscriptionFrequency;
	const setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	const toggleSubscriptionCount = outletContext.toggleSubscriptionCount;
	const setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	const subscriptionCount = outletContext.subscriptionCount;
	const setSubscriptionCount = outletContext.setSubscriptionCount
	const phoneCapture = outletContext.phoneCapture;
	const setPhoneCapture = outletContext.setPhoneCapture;
  	const [hasCause, setHasCause] = useState(false);

	// Pathname from params
	var pathname = useParams();

	// Set phone capture to false
	useEffect(() => {
		setPhoneCapture(false);
	},[])

  	// Pass the cause to the subcomponents if it exists 
  	useEffect(() => {
  		if (typeof specifiedCause.title != "undefined" || typeof pathname.cause != "undefined" )  {
  			setHasCause(true);
  		}

		// If user supplies a pathname without a cause
		if (typeof specifiedCause.title == "undefined" && typeof pathname.cause != "undefined"){
			let adHocCause = {
				title: pathname.cause.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()),
				pathname: pathname.cause,
				goalInclusion: true,
				isFixed: false
			} 
			setHasCause(true);
			setSpecifiedCause(adHocCause);
		}
  	},[specifiedCause, pathname])

	// Render Function	
	return (
		<div>
			{/*Sub-Header*/}
			{/*<hr className="mb-4"/>*/}
			{/*<p className = "text-center text-base font-light mb-5 mx-4" >Use the panel below to tally your donation. Once ready, select checkout to continue.</p>*/}
			<hr className="my-4 w-11/12 mx-auto lg:my-5"/>

			{/*Current Donation*/}
			<CurrentDonation currentDonation = {currentDonation} setCurrentDonation = {setCurrentDonation} orgID = {orgID} minDonation = {orgData.account_donation.minimum} sugDonation = {orgData.account_donation.suggested} hasCause = {hasCause} cause={specifiedCause} subscriptionEnabled={subscriptionDonation} subscriptionFrequency={subscriptionFrequency} subscriptionCount={subscriptionCount} toggleSubscriptionCount = {toggleSubscriptionCount}/> 
			<hr className="my-4 w-11/12 mx-auto lg:my-5"/>
			
			{/*Add Donation*/}
			<AddDonation serverURL = {serverURL} donationMenu = {orgData.account_donation.menu} currentDonation = {currentDonation} setCurrentDonation = {setCurrentDonation} dbsClicked = {dbsClicked} setdbsClicked = {setdbsClicked} hasCause = {hasCause} cause={specifiedCause} subscriptionDonation = {subscriptionDonation} setSubscriptionDonation = {setSubscriptionDonation} subscriptionFrequency = {subscriptionFrequency} setSubscriptionFrequency = {setSubscriptionFrequency} />

			{/* Subscription Donation */}
			<RecurringDonation subscriptionDonation = {subscriptionDonation} setSubscriptionDonation = {setSubscriptionDonation} subscriptionFrequency = {subscriptionFrequency} setSubscriptionFrequency = {setSubscriptionFrequency} toggleSubscriptionCount = {toggleSubscriptionCount} setToggleSubscriptionCount = {setToggleSubscriptionCount} subscriptionCount = {subscriptionCount} setSubscriptionCount = {setSubscriptionCount}/>

		</div>
	)


}

// EXPORT COMPONENT
// ========================================
export default DonationPage;