// --------------------
// lostPage.js |
// --------------------
// Page displays when user clicks an incorrect or invalid page.
// =========================================================================================================================================


// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import {useOutletContext} from "react-router-dom";
import axios from 'axios';

// COMPONENTS
// ========================================
import BannerImage from "../sub-components/bannerImage.jsx"
// import HeaderImage from "../sub-components/HeaderImage.jsx"
import Footer from "../sub-components/footer.jsx"

// SERVER CONNECTION
// ========================================
const server = require("../../connections/serverURL");
var server_url = server.getServer();

// ICONS
// ========================================
import icon from '../../assets/mosque.svg'

// LOADER COMPONENT
// ========================================

function LostPage(params){

		const outletContext = useOutletContext();
		const setBackgroundImage = outletContext.setBackgroundImage


		// Session ID 
		const [sessionID, setSessionID] = useState("");

		// Retrieve the session id from context
	  	const sessionContextData = useOutletContext().sessionID;

		// On Load.. Update the session information 
		useEffect(() => { 

		  	// console.log(sessionContextData);

		  	if(sessionContextData == "" ) {

		  		setSessionID(sessionContextData);
		  		// console.log(sessionID);

		  	}
		  	else {

				// Create Session
			 	var sessionUpdateURL = server_url + '/sessions/update/?sessionid=' + sessionContextData + "&updateparameter=app_data.hit_lost&updatevalue=true"

			 	// console.log(sessionUpdateURL);	  		

			 	// Store Results of API Call in Org Data and Set Loader to False
			 	const fetchData = async() => {
			 		const result = await axios(sessionUpdateURL);
			 	};

			 	// Run the Axios API Call and Track Whether Successful 
			 	fetchData();		

		  	}

		})

		useEffect(() => {
			setBackgroundImage("Wallpaper_H1.png")
		}, [])


	return (

		<div className="flex flex-col h-full">
			<BannerImage source="Banner_H3.png" server={server_url}/>

			{/* <HeaderImage server={server_url} source="Header_H1.png" /> */}
			<div className = "px-6 mt-10 flex-grow flex 3xl:justify-center 3xl:items-center">
				<div>
					<h1 className = "text-xl text-center font-bold mb-1 3xl:text-3xl 3xl:text-left">Looks Like You Might Be Lost...</h1>
					<hr className="my-4"/>
					<p className = "text-center text-base font-light mb-3 3xl:text-xl 3xl:text-left">Please re-enter the URL provided by your masjid or scan their QR code to complete your donation.</p>
					<hr className="my-4"/>
					<p className = "text-center text-base font-light mb-3 3xl:text-xl 3xl:text-left">Need more help? Shoot an email to <a href="mailto:support@givemasjid.com">support@givemasjid.com</a></p>
				</div>
			</div>


	    	{/*Page Footer*/}			
			<Footer/>

		</div>

	)
}


// Export Component
export default LostPage;



