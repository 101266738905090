// --------------------
// loader.js |
// --------------------
// Generic loader used when waiting for data retrieval to complete 
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React from 'react'

// ICONs
// ========================================
import icon from '../../assets/mosque.svg'

// LOADER COMPONENT
// ========================================

function Loader(params){
	return (

			<div className = "flex items-center justify-center w-screen max-w-screen-lg mt-10 lg:mt-8 3xl:max-w-screen-xl">
				<div className="justify-center 3xl:justify-start">
					<img className = "h-20 text-center mx-auto" src={icon} alt="Icon" />
					<h1 className = "text-4xl text-center font-bold mb-1">GiveMasjid</h1>
					<p className = "text-center text-lg font-light mb-2" >Loading</p>
					<div className="lds-grid text-center mx-auto my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				</div>
			</div>

	)
}


// Export Component
export default Loader;



