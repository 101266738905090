// --------------------
// helpFurtherPage.js |
// --------------------
// Stripe checkout sends the user to this page following a successful checkout. Page confirms completion of the process.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { useOutletContext, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ICONS
// ========================================
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// HOOKS
// ========================================
import { useInterval } from 'usehooks-ts'

// IMAGE
// ========================================
import Card from '../../assets/Card.png'


// LOADER COMPONENT
// ========================================

function AddOnPage(params){

	// Retrieve the relevant values from context
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const currentDonation = outletContext.currentDonation;
	const setCurrentDonation = outletContext.setCurrentDonation;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
  	const specifiedCause = outletContext.specifiedCause;
  	const setSpecifiedCause = outletContext.setSpecifiedCause;
	const subscriptionDonation = outletContext.subscriptionDonation;
	const subscriptionFrequency = outletContext.subscriptionFrequency;
	const toggleSubscriptionCount = outletContext.toggleSubscriptionCount;
	const subscriptionCount = outletContext.subscriptionCount; 
  	const terminalMode = outletContext.terminalMode;
  	const terminalReader = outletContext.terminalReader;
  	const terminalLive = outletContext.terminalLive;
	const phoneCapture = outletContext.phoneCapture;
	const setPhoneCapture = outletContext.setPhoneCapture;
  	const [hasCause, setHasCause] = useState(false);
	const currency = orgData.account_payment.currency;

	// Initial State Variables
	const [proceedToCheckout, setProceedToCheckout] = useState(false);

	// Retrieve Search Parameters
  	const [searchParams, setSearchParams] = useSearchParams();
  	const [checkoutCause, setCheckoutCause] = useState("");
  	const [goalInclusion, setGoalInclusion] = useState(true);

  	// PollInterval 
	var pollInterval;

  	// Pass the cause to the subcomponents if it exists 
  	useEffect(() => {
  		if (typeof specifiedCause.title != "undefined" )  {
  			setHasCause(true);
  			setCheckoutCause(specifiedCause.title);
  			setGoalInclusion(specifiedCause.goalInclusion);
  		}
  		else {
  			setCheckoutCause("General")
  			setGoalInclusion(true);
  		}
  	}, [specifiedCause])

	// Router Navigation
 	let location = useLocation();
 	// console.log(location);
	let navigate = useNavigate();
	// console.log(location)

	// Grab Current Value and Percentage Increase Value
	var currentValue = currentDonation;
	var feeStructure = orgData.account_payment;
	var increaseValue = ((currentValue+(feeStructure.stripe_fee.fixed_fee + feeStructure.application_fee.fixed_fee))/(1-(feeStructure.stripe_fee.percent_fee + feeStructure.application_fee.percent_fee))) - currentValue;
	var appFee = feeStructure.application_fee.fixed_fee + (currentValue * feeStructure.application_fee.percent_fee);
	var stripeFee = feeStructure.stripe_fee.fixed_fee + (currentValue * feeStructure.stripe_fee.fixed_fee)
	var totalFee = (feeStructure.application_fee.percent_fee + feeStructure.stripe_fee.percent_fee) * currentValue + (feeStructure.application_fee.fixed_fee + feeStructure.stripe_fee.fixed_fee)

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Update Session to register the time the page was reached 
	useEffect(() => {

		// Update Session
	 	var sessionUpdateURL = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.start_times.addon_page&updatevalue=" + Date.now();

	 	// Store Results of API Call in Org Data and Set Loader to False
	 	const fetchData = async() => {
	 		const result = await axios(sessionUpdateURL);
	 	};

		fetchData();	
	
	}, []);

 	// Prevent user from going straight to this page without having added anything to the checkout
	useEffect(() => {

		// If there is a stripe session id
		if(!currentDonation > 0 ) { 
			navigate( { pathname: "/" + orgID + "/Set-Donation"})
		}

	}, [outletContext])

	// Clicks cancel (Register that the cancel button was clicked )
	function cancelClick(e) {

	 	var sessionUpdateURL_2 = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.clicked_addon_cancel&updatevalue=true";

	 	const fetchData = async() => {
	 		const result = await axios(sessionUpdateURL_2);
	 	};

	 	if(sessionID != "") {
		 	// Run the Axios API Call and Track Whether Successful 
		 	fetchData();
			navigate("/" + orgID + "/Set-Donation"); 
	 		
	 	}

	}

	// Update Session to register the time the checkout page was initiated 
	useEffect(() => {
	
		if(proceedToCheckout) {

			// Update Session
		 	var sessionUpdateURL2 = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.start_times.stripe_checkout&updatevalue=" + Date.now();

		 	// Store Results of API Call in Org Data and Set Loader to False
		 	const fetchData = async() => {
		 		const result = await axios(sessionUpdateURL2);
		 	};

			fetchData();	

		}

	}, [proceedToCheckout]);

	// Change the page
	useEffect(() => {

		// Non-Terminal Mode Check-Out 
		if(proceedToCheckout && !terminalMode) {

			var checkoutUrl = serverURL + "/checkout?donation=" + currentDonation + "&orgid=" + orgID + "&stripeid=" + orgData.account_payment.stripe_id + "&appsession=" + sessionID + "&ein=" + orgData.account_info.ein + "&orgname=" + orgData.account_info.display_name + "&af=" + appFee + "&acstatus=" + orgData.account_status + "&cause=" + checkoutCause + "&goalInclusion=" + goalInclusion + "&currency=" + currency;

			// Check if the org has the weekly goal feature enabled
			if(orgData.account_features.goal_tracker) {
				checkoutUrl = checkoutUrl + "&goalfeature=enabled"
			}

			// Check if the org has the email receipt feature enabled
			if(orgData.account_features.email_receipts) {
				checkoutUrl = checkoutUrl + "&receiptfeature=enabled"
			}

			// Check if the org has the donation feed enabled 
			if(orgData.account_features.donation_feed) {
				checkoutUrl = checkoutUrl + "&feedfeature=enabled"
			}

			// Check if the subscription toggle is active
			if(subscriptionDonation) {
				// checkoutUrl = checkoutUrl + "&subscription=true";
				checkoutUrl = checkoutUrl + "&subscription=true" + "&frequency=" + subscriptionFrequency;
			
				// Check if user has specified a number of donations
				if(toggleSubscriptionCount){
					checkoutUrl = checkoutUrl + "&donationcount=" + subscriptionCount;
				}
			}

			// Check if phone capture is true
			if(phoneCapture) {
				checkoutUrl = checkoutUrl + "&phonecapture=true";
			}
			else{
				checkoutUrl = checkoutUrl + "&phonecapture=false";
			}

			// Navigate to the checkout URL
			window.location.replace(checkoutUrl);

		}

		// Terminal Mode Checkout
		if(proceedToCheckout && terminalMode) {

			var paymentIntentId;
			var readerId;
			var pollCount = 0;

			var terminalPost = { amount: currentDonation, stripeid: orgData.account_payment.stripe_id, app_fee: totalFee, status: terminalLive, cause: checkoutCause, orgid: orgID}

			// Check if the org has the weekly goal feature enabled
			if(orgData.account_features.goal_tracker) {
				terminalPost.goalfeature = "enabled";
			}

			// Check if the org has the email receipt feature enabled
			if(orgData.account_features.email_receipts) {
				terminalPost.receiptfeature = "enabled";
			}

			// Check if the org has the donation feed enabled 
			if(orgData.account_features.donation_feed) {
				terminalPost.feedfeature = "enabled";
			}

			console.log("Terminal Post");
			console.log(terminalPost);

			function createPaymentIntent(amount) {
			  return fetch(serverURL + "/terminal/create_payment_intent", {
			    method: "POST",
			    headers: { "Content-Type": "application/json" },
			    body: JSON.stringify(terminalPost),
			  }).then(function(response) {
			    return response.json();
			  });
			}

			function processPayment() {
			  return fetch(serverURL + "/terminal/process_payment", {
			    method: "POST",
			    headers: { "Content-Type": "application/json" },
			    body: JSON.stringify({
			      reader_id: terminalReader,
			      payment_intent_id: paymentIntentId,
			      status: terminalLive
			    }),
			  }).then((response) => {
			    return response.json();
			  });
			}


			function checkPaymentCompletion() {

			   pollCount = pollCount +1;

			   axios.post(serverURL + "/terminal/payment_complete", {payment_intent_id: paymentIntentId})
			   .then(function(response){

				   console.log(response);

				   if (response.data.payment == "complete") {
					   	clearInterval(pollInterval);
					   	console.log("Payment complete");
		   	  			setSearchParams("?terminal=true&reader=" + terminalReader + "&live=" + terminalLive)
					   	navigate("/" + orgID + "/Donation-Successful/")
				   }

				   if(pollCount>120) {
				   		clearInterval(pollInterval);
          				axios.post(serverURL + "/terminal/cancel_payment", {reader_id: terminalReader, status: terminalLive})
						navigate("/" + orgID); 

				   }

				   else {
				   		console.log("Payment Pending");
				   }

			   })

			}

			// Create Payment Intent and Process It
			createPaymentIntent(currentDonation).then(function(paymentIntent) {
				pollCount = 0;
		    	paymentIntentId = paymentIntent["id"];
		   		processPayment();
		   		pollInterval = setInterval(checkPaymentCompletion, 2000)

		  });
		}

	}, [proceedToCheckout]);
	
	return (

		<div className="flex flex-col">

			{/*Header Line*/}
			<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
			
			<div>
				{/*Current Donation*/}

				<div className="px-4">
				<h2 className="text-base font-bold text-center mb-1 3xl:mb-3 3xl:text-xl">{specifiedCause.title == undefined ? "Your Donation" : specifiedCause.title} </h2>
			    <input type="text" name="price" id="price" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-3xl font-extrabold text-center rounded-full 3xl:py-3" readOnly value={formatter.format(currentValue)}></input>
				
				</div>

				{/*Non-Terminal Mode*/}
				<div className={`${terminalMode ? "hidden": ""}`}> 

					{/*Increase Prompt */}
					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
					<h2 className={`text-base font-bold text-center mb-1 3xl:text-xl ${proceedToCheckout ? "hidden" : ""}`}>Increase Your Impact</h2>
					<h2 className={`text-base font-bold text-center mb-1 3xl:text-xl ${proceedToCheckout ? "" : "hidden"}`}>Redirecting to Checkout...</h2>
					<p className = {`text-center text-base font-light mb-5 mx-10 3xl:text-xl ${proceedToCheckout ? "hidden" : ""}` }> Are you open to increasing your donation by <span className="font-bold">{formatter.format(increaseValue)}</span> to help cover our card processing fees?</p>
					<p className = {`text-center text-base 3xl:text-xl font-light mb-5 mx-10 ${proceedToCheckout ? "" : "hidden"}` }> You will now be redirected to a checkout page where you can securely complete your donation.</p>

				</div>

				{/*Terminal Mode*/}
				<div className={`${terminalMode ? "": "hidden"}`}> 

					{/*Increase Prompt */}
					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
					<h2 className={`text-base font-bold text-center mb-1 ${proceedToCheckout ? "hidden" : ""}`}>Increase Your Impact</h2>
					<h2 className={`text-base font-bold text-center mb-1 ${proceedToCheckout ? "" : "hidden"}`}>Insert Your Credit Card Now</h2>
					<p className = {`text-center text-base font-light mb-5 mx-10 ${proceedToCheckout ? "hidden" : ""}` }> Are you open to increasing your donation by <span className="font-bold">{formatter.format(increaseValue)}</span> to help cover our card processing fees?</p>
					<p className = {`text-center text-base font-light mb-5 mx-10 ${proceedToCheckout ? "" : "hidden"}` }> Follow the prompt on the credit card terminal to complete your donation.</p>
					<img className = {`text-center mx-auto w-2/5 ${proceedToCheckout ? "" : "hidden"}`} src={Card}/>

					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

					<h2 className={`text-base font-bold text-center mb-1 ${proceedToCheckout ? "" : "hidden"}`}>Or Click to Cancel Your Donation</h2>
					<div className="flex">

						<div className="mx-auto mb-10">

							<button  
			          			onClick={() => { 
								   	clearInterval(pollInterval);
			          				axios.post(serverURL + "/terminal/cancel_payment", {reader_id: terminalReader, status: terminalLive})
									navigate("/" + orgID); 
			      				}}
								id="no_btn" className={`rounded-xl opacity-50 hover:opacity-100 active:opacity-100 text-base py-3 mt-3 w-full px-7 bg-rose-500 text-white ${proceedToCheckout ? "" : "hidden"}`}> <FontAwesomeIcon icon={faCircleXmark}/> Return to Main Menu  
							</button>
						</div>

					</div>
				</div>

			</div>

			<div className="flex-grow">

				{/*Buton Confirmations*/}
				<div className={`px-4 ${proceedToCheckout ? "hidden" : ""}`}>
					<button  
	          			onClick={() => { 
	          				currentValue = currentValue + increaseValue;
	          				setCurrentDonation(currentValue);
						 	var sessionUpdateURL = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.covered_expenses&updatevalue=true";

						 	// Store Results of API Call in Org Data and Set Loader to False
						 	const fetchData = async() => {
						 		const result = await axios(sessionUpdateURL);
						 	};

						 	// Run the Axios API Call and Track Whether Successful 
						 	fetchData();			

	          				setProceedToCheckout(true);
	      				}}
						id="ys_btn" className="rounded-full text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-2xl mx-auto text-center py-3 3xl:py-5 mt-2 w-full border-2"> <FontAwesomeIcon icon={faCircleCheck}/> Yes </button>
					<button  
	          			onClick={() => { 
	          				setProceedToCheckout(true);
	      				}}
						id="no_btn" className="rounded-full opacity-60 hover:opacity-100 active:opacity-100 text-black bg-[#fffff6] text-2xl mx-auto text-center py-2 3xl:py-4 mt-3 w-full border-gray-300 border-2"> <FontAwesomeIcon icon={faCircleXmark}/> No 
					</button>

					<button  

						// component ={Link} to="/Add-On-Donation"
	          			onClick={cancelClick}

						id="can_btn" className=" bg-transparent opacity-30 text-black text-xs mx-auto block py-3 px-3 drop-shadow-xl 3xl:text-base">
						<FontAwesomeIcon icon={faArrowRotateLeft}/> Cancel
					</button>

				</div>
			</div>

		</div>
	)
}


// Export Component
export default AddOnPage;



