// -----------------------
// customDonationPage.js |
// -----------------------
// Page controls the UI associated with a user's preference of entering a donation amount manually.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect, useRef} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import axios from 'axios';

// COMPONENTS
// ========================================
import RecurringDonation from '../sub-components/recurringDonations.jsx';

// ICONS
// ========================================
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons'


// COMPONENT
// ========================================
function ChooseDonation (params) {

	// Value of the custom donation
	const [customDonation, setcustomDonation] = useState(0);
	const [checkoutEnabled, setCheckoutEnabled] = useState(false);
	const [suggestedEnabled, setSuggestedEnabled] = useState(false);

	// Router Navigation
 	let location = useLocation();
	let navigate = useNavigate();

	// Retrieve the relevant values from context
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const currentDonation = outletContext.currentDonation;
	const setCurrentDonation = outletContext.setCurrentDonation;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
	const subscriptionDonation = outletContext.subscriptionDonation;
	const setSubscriptionDonation = outletContext.setSubscriptionDonation;
	const subscriptionFrequency = outletContext.subscriptionFrequency;
	const setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	const toggleSubscriptionCount = outletContext.toggleSubscriptionCount;
	const setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	const subscriptionCount = outletContext.subscriptionCount;
	const setSubscriptionCount = outletContext.setSubscriptionCount

	// Textbox selection
	const textBoxRef = useRef(null);

	// Update Session to register that the custom button was clicked and the time of the click
	useEffect(() => {

	 	var sessionUpdateURL_1 = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.clicked_custom&updatevalue=true";
	 	var sessionUpdateURL_2 = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.start_times.custom_page&updatevalue=" + Date.now();

	 	const fetchData = async() => {
	 		const result = await axios(sessionUpdateURL_1);
	 		const result_2 = await axios(sessionUpdateURL_2);

	 	};

	 	if(sessionID != "") {
		 	// Run the Axios API Call and Track Whether Successful 
		 	fetchData();	 		
	 	}
 	}, []);

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	    minimumFractionDigits: 0,
	});

	// Input Handler (Update the Current Context Value)
	function handleInput(e) {
		if(event.target.value != ""){
			
			setCurrentDonation(parseFloat(event.target.value));
		}
		else{
			e.target.placeholder=0;
			setCurrentDonation(0);
		}
	}

	// Clicks cancel
	function cancelClick(e) {

	 	var sessionUpdateURL_3 = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.clicked_custom_cancel&updatevalue=true";

	 	const fetchData = async() => {
	 		const result = await axios(sessionUpdateURL_3);
	 	};

	 	if(sessionID != "") {
		 	// Run the Axios API Call and Track Whether Successful 
		 	fetchData();
			navigate("/" + orgID + "/Set-Donation"); 
	 		
	 	}

	}


	// Check if the current value exceeds a set number
	useEffect(() => {
		if(currentDonation>=orgData.account_donation.minimum){
			setCheckoutEnabled(true);
		}
		else {
			setCheckoutEnabled(false);
		}

		if(currentDonation>=orgData.account_donation.suggested){
			setSuggestedEnabled(true);
		}
		else {
			setSuggestedEnabled(false);
		}
	}, [currentDonation]);

	// Textbox Focus
	useEffect(() => {
		textBoxRef.current.focus();
	  }, []);

	return (
		<div>
			<div className="px-4">
				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				<h2 className="text-base font-bold text-center mb-1 3xl:text-xl">Specify Your Donation <span className={`text-xs 3xl:text-base font-light rounded-full  text-slate-600 ${checkoutEnabled ? "hidden" : ""}`}>| Min: ${orgData.account_donation.minimum} </span> <span className={`text-xs 3xl:text-base font-light py-1 rounded-full text-slate-600 ${suggestedEnabled ? "hidden" : ""}`}>| Suggested: ${orgData.account_donation.suggested} </span></h2>
				<p className="text-sm text-center mb-5 3xl:text-lg">What would you like to set your donation?</p>

				<div className="mt-1 relative rounded-md">

					<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<span className=" text-gray-700 text-xl font-bold"> $ </span>
					</div>
					<input type="number" min="0.00" step="5.00" name="don_input" id="don_input" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-3xl font-extrabold text-center rounded-full 3xl:py-3" placeholder={parseFloat(currentDonation).toFixed(2)}
						onChange={handleInput} ref={textBoxRef}>	
					</input>
				</div>

				<div className="visible">
					<button  

						// component ={Link} to="/Add-On-Donation"
						onClick={() => { 
							navigate("/" + orgID + "/Help-Further"); 
						}}

						id="chk_btn" className="disabled:bg-gray-400 disabled:opacity-70 rounded-full text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-lg mx-auto text-center py-3 mt-5 block w-full drop-shadow-xl 3xl:py-5 3xl:text-xl" disabled={!checkoutEnabled}>
						<FontAwesomeIcon icon={faCircleCheck}/> Checkout 
					</button>	

					<button  

						// component ={Link} to="/Add-On-Donation"
						onClick={cancelClick}

						id="ca_btn" className=" bg-transparent opacity-30 text-black text-xs mx-auto block py-3 px-3 drop-shadow-xl 3xl:text-base">
						<FontAwesomeIcon icon={faArrowRotateLeft}/> Cancel
					</button>
		
				</div>	
			</div>

			{/* Subscription Donation */}
			<RecurringDonation subscriptionDonation = {subscriptionDonation} setSubscriptionDonation = {setSubscriptionDonation} subscriptionFrequency = {subscriptionFrequency} setSubscriptionFrequency = {setSubscriptionFrequency} toggleSubscriptionCount = {toggleSubscriptionCount} setToggleSubscriptionCount = {setToggleSubscriptionCount} subscriptionCount = {subscriptionCount} setSubscriptionCount = {setSubscriptionCount}/>
		</div>
	)
}


// Export Component
export default ChooseDonation;