import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Loader from '../sub-components/loader.jsx'
import Footer from "../sub-components/footer.jsx"

export const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (

      <div className="flex flex-col h-full">

        <div className="flex-grow">
          <div className = "flex items-center justify-center">
            <Loader className=""/>
          </div>
        </div>

          {/*Page Footer*/}       
          {/* <hr className="mt-4 mb-2 w-11/12 mx-auto"/> */}
        <Footer/>
      </div>


    ),
  });

  return <Component />;
};
