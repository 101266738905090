// --------------------
// MasjidManagerPage.js |
// --------------------
// Page for controlling the masjid account features available to account administrators
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import {Link, useOutletContext, useSearchParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
const { DateTime } = require("luxon");

// COMPONENTS
// ========================================
import Footer from "../sub-components/footer.jsx"

// SERVER CONNECTION
// ========================================
const server = require("../../connections/serverURL");
var server_url = server.getServer();

// ICONS
// ========================================
import icon from '../../assets/mosque.svg'
import HeaderImage from "../sub-components/HeaderImage.jsx"
import themePreview1 from '../../assets/Header_1.png'
import themePreview2 from '../../assets/Header_2.png'
import themePreview3 from '../../assets/Header_3.png'
import themePreview4 from '../../assets/Header_4.png'
import themePreview5 from '../../assets/Header_5.png'
import themePreview6 from '../../assets/Header_6.png'
import themePreview7 from '../../assets/Header_7.png'
import themePreview8 from '../../assets/Header_8.png'
import themePreview9 from '../../assets/Header_9.png'
import themePreview10 from '../../assets/Header_10.png'
import themePreview11 from '../../assets/Header_11.png'
import themePreview12 from '../../assets/Header_12.png'
import themePreview13 from '../../assets/Header_13.png'
import themePreview14 from '../../assets/Header_14.png'
import themePreview15 from '../../assets/Header_15.png'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'


// Masjid Manager COMPONENT
// ========================================

function MasjidManagerPage(params){

	// Authentication
  	const { user } = useAuth0();

	// let navigate = useNavigate();	

	// Search Params to pull the org id post login
  	let [searchParams, setSearchParams] = useSearchParams();

	// Data for retrieval
	const [orgID, setOrgID] = useState({});
	const [userData, setUserData] = useState({});

	let blankOrgData = {
		"orgID": "",
		"masjidDisplayName": "",
		"headline": "",
		"welcomeMessage": "",
		"theme": "",
		"minimumDonation": "",
		"suggestedDonation": "",
		"goalFeatureEnabled": "",
		"donationFeedEnabled": "",
		"receiptFeatureEnabled": "",
		"causeFeatureEnabled": "",
		"subscriptionFeatureEnabled": "",
		"islamicHolidayEnabled": "",
		"schoolFeatureEnabled": "",
		"currentlyRaised": "",
		"targetGoal": "",
		"goalFrequency": "",
		"nextGoalUpdate": "",
		"causeData": [], 
		"schoolName": "",
		"enrollmentStartDate": "",
		"enrollmentEndDate": "",
		"oneTimeSchoolFees": [],
		"monthlySchoolFees": [],
	} 

	const [orgData, setOrgData] = useState(blankOrgData);

	// Display the Goal Features
	const [goalDisplay, setGoalDisplay] = useState(false);

	// Edit Features
	const [loginPaneLocked, setLoginPaneLocked] = useState(true);
	const [infoPaneLocked, setInfoPaneLocked] = useState(true);
	const [welcomePaneLocked, setWelcomePaneLocked] = useState(true);
	const [themePaneLocked, setThemePaneLocked] = useState(true);
	const [donationPaneLocked, setDonationPaneLocked] = useState(true);
	const [featuresPaneLocked, setFeaturesPaneLocked] = useState(true);
	const [goalsPaneLocked, setGoalsPaneLocked] = useState(true);
	const [causePaneLocked, setCausePaneLocked] = useState(true);
	const [designationsLoaded ,setDesignationsLoaded] = useState(false);
	const [schoolPaneLocked, setSchoolPaneLocked] = useState(true)
	const [schoolsLoaded, setSchoolsLoaded] = useState(false)

	// Theme Preview
	const [themePreview, setThemePreview] = useState(themePreview1)

	// Cause Pane Toggle
	const [causePaneToggle, setCausePaneToggle] = useState(false);

	// Context Data for retrieving the server URL 
	const outletContext = useOutletContext();
  	const sessionID = outletContext.sessionID;
  	const setSessionID = outletContext.setSessionID;
  	const serverURL = outletContext.serverURL;
	const setBackgroundImage = outletContext.setBackgroundImage;

  	// Set the search parameter based on the auth token for user
  	useEffect(() => {
	  	
	  	setSearchParams({"org": user.org})
	  	
  	}, [user])

	// Set the initial background image
	useEffect(() => {
		setBackgroundImage("Wallpaper_H4.png")
	}, [])
	

  	// Make API Call to retrieve the org  data
	useEffect(() => {

		// Set the OrgID
		setOrgID(user.org)

		// Build an Org API Endpoint
	 	var orgurl = serverURL + '/api/account/?orgid=' + user.org;
 
	 	// Retrieve data associated with the org
	 	const fetchData = async() => {

	 		try {
		 		const result = await axios(orgurl); 

		 		var loadedData = {
					"orgID": result.data.account_id.primary,
					"masjidDisplayName": result.data.account_info.display_name,
					"headline": result.data.account_message.title,
					"welcomeMessage": result.data.account_message.subtitle,
					"theme": result.data.account_theme.header_image,
					"minimumDonation": result.data.account_donation.minimum,
					"suggestedDonation": result.data.account_donation.suggested,
					"donationFeedEnabled": result.data.account_features.donation_feed,		
					"goalFeatureEnabled": result.data.account_features.goal_tracker,
					"receiptFeatureEnabled": result.data.account_features.email_receipts,
					"causeFeatureEnabled": result.data.account_features.specify_cause,
					"subscriptionFeatureEnabled": result.data.account_features.subscription,
					"islamicHolidayFeatureEnabled": result.data.account_features.islamic_holidays,
					"schoolFeatureEnabled": result.data.account_features.islamic_school,
					"currentlyRaised": "",
					"targetGoal": "",
					"goalFrequency": "",
					"nextGoalUpdate": "",
					"causeData": [],
					"schoolName": "",
					"enrollmentStartDate": "",
					"enrollmentEndDate": "",
					"oneTimeSchoolFees": [],
					"monthlySchoolFees": [],
				} 

	 			setOrgData(loadedData);

	 			// Set the initial value of the preview iamge
				if(loadedData.theme == "Header_1.png"){
					setThemePreview(themePreview1)
				}
				else if(loadedData.theme == "Header_2.png"){
					setThemePreview(themePreview2)
				}
				else if(loadedData.theme == "Header_3.png"){
					setThemePreview(themePreview3)
				}
				else if(loadedData.theme == "Header_4.png"){
					setThemePreview(themePreview4)
				}
				else if(loadedData.theme == "Header_5.png"){
					setThemePreview(themePreview5)
				}
				else if(loadedData.theme == "Header_6.png"){
					setThemePreview(themePreview6)
				}
				else if(loadedData.theme == "Header_7.png"){
					setThemePreview(themePreview7)
				}
				else if(loadedData.theme == "Header_8.png"){
					setThemePreview(themePreview8)
				}
				else if(loadedData.theme == "Header_9.png"){
					setThemePreview(themePreview9)
				}

				else if(loadedData.theme == "Header_10.png"){
					setThemePreview(themePreview10)
				}

				else if(loadedData.theme == "Header_11.png"){
					setThemePreview(themePreview11)
				}

				else if(loadedData.theme == "Header_12.png"){
					setThemePreview(themePreview12)
				}

				else if(loadedData.theme == "Header_13.png"){
					setThemePreview(themePreview13)
				}

				else if(loadedData.theme == "Header_14.png"){
					setThemePreview(themePreview14)
				}

				else if(loadedData.theme == "Header_15.png"){
					setThemePreview(themePreview15)
				}
				else{
					setThemePreview(themePreview1)
				}

				// Check to laod the goal data. (All records must have a placeholder goal data set up)
				if(true){
					// Build an Org API Endpoint to query the goals API
	 				var goalurl = serverURL + '/api/goals/?orgid=' + user.org;

	 				const fetchGoals = async() => {

	 					try {
		 					const goalresult = await axios(goalurl); 
		 					// console.log(goalresult);

		 					setOrgData(orgData => ({...orgData, "currentlyRaised": goalresult.data.raised}))
		 					setOrgData(orgData => ({...orgData, "targetGoal": goalresult.data.current_goal}))
		 					setOrgData(orgData => ({...orgData, "goalFrequency": goalresult.data.frequency}))
		 					setOrgData(orgData => ({...orgData, "nextGoalUpdate": DateTime.fromISO(goalresult.data.next_update).toFormat("yyyy-MM-dd")}))

	 					}
	 					catch(err){
	 						console.log(err);
	 					}
	 				}

	 				fetchGoals();

				}

				// Checks to load the designations data. (All records must have placeholder data with designations)
				if(true) {

					// Build an Org API Endpoint to query the designations API
	 				var designationURL = serverURL + '/designations/?orgid=' + user.org;
	 				// console.log(designationURL);

					const fetchDesignations = async() => {

						try {

							const designationresult = await axios(designationURL);
		 					// console.log(designationresult)
		 					setDesignationsLoaded(true);
		 					setOrgData(orgData => ({...orgData, "causeData": designationresult.data.designations}));

						}

						catch(err) {
							setDesignations(false);
							console.log(err);
						}
					}
					fetchDesignations();
				}

				// Check to load the school data. (All records must have placeholder data with school information)
				if (true) {
					// Build an Org API Endpoint to query the schools API
					var schoolURL = serverURL + '/api/schools?orgid=' + user.org;
					// console.log(schoolURL);

					const fetchSchools = async () => {
						try {
							const schoolResult = await axios(schoolURL);
							setSchoolsLoaded(true);

							setOrgData(orgData => ({...orgData, "schoolName": schoolResult.data.school.school_name}))
							setOrgData(orgData => ({...orgData, "enrollmentStartDate": DateTime.fromISO(schoolResult.data.school.enrollment_start_date).toFormat("yyyy-MM-dd")}))
							setOrgData(orgData => ({...orgData, "enrollmentEndDate": DateTime.fromISO(schoolResult.data.school.enrollment_end_date).toFormat("yyyy-MM-dd")}))
							setOrgData(orgData => ({...orgData, "oneTimeSchoolFees": schoolResult.data.school.fees.one_time_price}))
							setOrgData(orgData => ({...orgData, "monthlySchoolFees": schoolResult.data.school.fees.payment_plan_price}))

							console.log(orgData)

						} catch (err) {
							setSchoolsLoaded(false);
							console.log(err);
						}
					};
					fetchSchools();
				}

	 		}
	 		catch(err){
	 			console.log(err);

	 		}

	 	};

	 	// Fetch the data the moment the serverURL is ready
	 	if(serverURL != "" ){
		 	fetchData();
	 	}

	}, [searchParams, serverURL, designationsLoaded, schoolsLoaded]);

	// Toggle Cause Pane refreshes the 

	// Save Clicks
  	const handleSave = (e) => {

  		let clickedPanel = e.target.value;
    	let orgURL = serverURL + "/settings"

  		if(clickedPanel == "saveInfoPane"){
  			setInfoPaneLocked(true);
    		axios.post(orgURL, {"pane": "infoPane", "orgID": user.org, "masjidDisplayName": orgData.masjidDisplayName})
  		}
 
   		if(clickedPanel == "saveWelcomePane"){
			setWelcomePaneLocked(true);
    		axios.post(orgURL, {"pane": "welcomePane", "orgID": user.org, "headline": orgData.headline, "welcomeMessage": orgData.welcomeMessage})
  		}

		if(clickedPanel == "saveThemePane"){
			setThemePaneLocked(true);
    		axios.post(orgURL, {"pane": "themePane", "orgID": user.org, "theme": orgData.theme})
  		}

		if(clickedPanel == "saveDonationPane"){
			setDonationPaneLocked(true);
    		axios.post(orgURL, {"pane": "donationPane", "orgID": user.org, "minimumDonation": orgData.minimumDonation, "suggestedDonation": orgData.suggestedDonation})
  		}

		if(clickedPanel == "saveFeaturesPane"){
			setFeaturesPaneLocked(true);
    		axios.post(orgURL, {
  			"pane": "featuresPane",
  			"orgID": user.org,
  			"goalFeatureEnabled": orgData.goalFeatureEnabled,
  			"donationFeedEnabled": orgData.donationFeedEnabled,
  			"receiptFeatureEnabled": orgData.receiptFeatureEnabled,
  			"causeFeatureEnabled": orgData.causeFeatureEnabled,
  			"subscriptionFeatureEnabled": orgData.subscriptionFeatureEnabled,
  			"islamicHolidayFeatureEnabled": orgData.islamicHolidayFeatureEnabled
  		})
  		}

 		if(clickedPanel == "saveGoalsPane"){
 			setGoalsPaneLocked(true);
    		axios.post(orgURL, {"pane": "goalsPane", "orgID": user.org, "raised": orgData.currentlyRaised,  "targetGoal": orgData.targetGoal,  "goalFrequency": orgData.goalFrequency,  "nextGoalUpdate": orgData.nextGoalUpdate, })
	  	}

 		if(clickedPanel == "saveCausePane"){
 			setCausePaneLocked(true);
    		axios.post(orgURL, {"pane": "causePane", "orgID": user.org,  "causes": orgData.causeData})
	  	}

		// #TODO: FIX THIS
		if(clickedPanel == "saveSchoolPane") {
			setSchoolPaneLocked(true)

			const enrollmentStartStartDate = new Date(orgData.enrollmentStartDate).toISOString();
			const enrollmentEndDate = new Date(orgData.enrollmentEndDate).toISOString();

			var schoolData = {
				school_name: orgData.schoolName,
				enrollment_start_date:enrollmentStartStartDate,
				enrollment_end_date: enrollmentEndDate,
				fees: {
					one_time_price: orgData.oneTimeSchoolFees,
					payment_plan_price: orgData.monthlySchoolFees
				}
			  };
			 axios.post(orgURL, {"pane": "schoolPane", "orgID": user.org,  "school": schoolData})
		}

  	}

  	// Theme Preview
  	const themeSelection = (e) => {
  		let selectedTheme = e.target.value;

  		// Set the Preview of the Theme
  		if(selectedTheme == "Header_1.png"){
  			setThemePreview(themePreview1);
  		}

  		if(selectedTheme == "Header_2.png"){
  			setThemePreview(themePreview2);
  		}

  		if(selectedTheme == "Header_3.png"){
  			setThemePreview(themePreview3);
  		}

  		if(selectedTheme == "Header_4.png"){
  			setThemePreview(themePreview4);
  		}

  		if(selectedTheme == "Header_5.png"){
  			setThemePreview(themePreview5);
  		}

  		if(selectedTheme == "Header_6.png"){
  			setThemePreview(themePreview6);
  		}

  		if(selectedTheme == "Header_7.png"){
  			setThemePreview(themePreview7);
  		}

  		if(selectedTheme == "Header_8.png"){
  			setThemePreview(themePreview8);
  		}
  		if(selectedTheme == "Header_9.png"){
  			setThemePreview(themePreview9);
  		}

  		if(selectedTheme == "Header_10.png"){
  			setThemePreview(themePreview10);
  		}

  		if(selectedTheme == "Header_11.png"){
  			setThemePreview(themePreview11);
  		}

  		if(selectedTheme == "Header_12.png"){
  			setThemePreview(themePreview12);
  		}

  		if(selectedTheme == "Header_13.png"){
  			setThemePreview(themePreview13);
  		}

  		if(selectedTheme == "Header_14.png"){
  			setThemePreview(themePreview14);
  		}

  		if(selectedTheme == "Header_15.png"){
  			setThemePreview(themePreview15);
  		}
  		// Change the Org Data Value
		setOrgData(orgData => ({...orgData, "theme": e.target.value}))

  	}

  	// Designation Change Handler
  	function causeChangeHandler(e, key){
  		var tempCause = orgData.causeData;

  		// Pathname cleanup
  		if(e.target.name == "pathname"){
  			e.target.value = e.target.value.replace(/\s+/g, '-').toLowerCase();
  		}

  		// Non-Check Boxes
  		if(e.target.name == "goalInclusion") {
  			tempCause[key][e.target.name] = e.target.checked;
  		}

  		else if(e.target.name == "isFixed") {
  			tempCause[key][e.target.name] = e.target.checked;
  		}

  		else if(e.target.name == "fixedValue" ) {
	  		tempCause[key][e.target.name] = parseFloat(e.target.value);		
  		}

  		// Check Boxes
  		else {
	  		tempCause[key][e.target.name] = e.target.value;		
  		}

		setOrgData(orgData => ({...orgData, "causeData": tempCause}))
		// console.log(orgData)  	
  	}

  	// Designation Delete Handler
  	function handleDeleteCause(e, key){
  		var tempCause = orgData.causeData;
  		tempCause.splice(key, 1);
		setOrgData(orgData => ({...orgData, "causeData": tempCause}));
		// console.log(orgData);
  	}

  	// Designation Addition Handler
  	const handleAddCause = (e) => {
  		// console.log(e.target.value)
  		var newCauseID = orgData.causeData.length + 1
  		var newCause = {
  			"id": newCauseID,
  			"pathname": "Cause-" + newCauseID,
  			"title": "Cause " + newCauseID + " Donation",
  			"description": "Enter description here",
  			"goalInclusion": true,
  			"isFixed": false,
  			"fixedValue": ""
  		}
  		var tempCause = orgData.causeData;
  		tempCause.push(newCause)

		setOrgData(orgData => ({...orgData, "causeData": tempCause}));
		// console.log(orgData);
  	}

	// Designation Renderer
	function renderCauseButtons() {
		if(orgData.causeFeatureEnabled && designationsLoaded){	
			return( 		
				orgData.causeData.map((cause, i) => 

			    	<div key={i}>
						<div className={`flex mt-4 ${causePaneLocked ? "" : "hidden"}`}>
						    <input type="text" name={"cause-" + i } id={"cause-" + i }  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={causePaneLocked} value={cause.title} readOnly>
					    	</input>
				    	</div>

						<div className={`flex mt-4 ${causePaneLocked ? "hidden" : ""}`}>
							<div className="border w-full rounded-sm px-4 py-4">
								<div className="mb-3">
							      	<label className="font-semibold text-sm mx-3 text-center"htmlFor={"title-" + i}>Designation Title</label>
								    <input type="text" name="title" id={"title-" + i} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={causePaneLocked} value= {cause.title} onChange={event => causeChangeHandler(event, i)}>
							    	</input>	
						    	</div>

						    	<div className="mb-3">
							      	<label className="font-semibold text-sm mx-3 text-center" htmlFor={"pathname-" +i}>URL Path</label>
								    <input type="text" name="pathname" id={"pathname-" +i} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={causePaneLocked} value={cause.pathname} 
								    	onChange={event => causeChangeHandler(event, i)}>
							    	</input>
						    	</div>

						    	<div className="mb-3">
							      	<label className="font-semibold text-sm mx-3 text-center" htmlFor={"description-" + i}>Description</label>
								    <textarea name="description" id={"description-" + i} className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={causePaneLocked} value={cause.description} onChange={event => causeChangeHandler(event, i)}>
							    	</textarea>
						    	</div>		

						    	<div className="mb-3">
							     	<input type="checkbox" id={"goalInclusion-" + i} name="goalInclusion" disabled={causePaneLocked} checked={cause.goalInclusion} onChange={event => causeChangeHandler(event, i)} />
							      	<label className="font-medium text-md mx-3 text-center" htmlFor={"goalInclusion-"+i}>Include in Goal Calculation?</label>
						    	</div>		

						    	<div className="mb-3">
							     	<input type="checkbox" id={"isFixed-" + i} name="isFixed" disabled={causePaneLocked} checked={cause.isFixed} onChange={event => causeChangeHandler(event, i)} />
							      	<label className="font-medium text-md mx-3 text-center" htmlFor={"isFixed-"+i}>Fixed Fee?</label>
								    <input type="number" name="fixedValue" id={"fixedValue-"+i} className="focus:ring-indigo-500 w-full focus:border-indigo-500 block mt-2 border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={causePaneLocked || !cause.isFixed} value={cause.fixedValue ? cause.fixedValue : ""} onChange={event => causeChangeHandler(event, i)}>
							    	</input>
						    	</div>		

						    	<div className="flex justify-end">
									<button className="rounded-md border text-center text-white bg-transparent text-black hover:bg-red-400 hover:text-white active:bg-red-400 text-xs px-3 py-2 mx-1 disabled:bg-gray-400 disabled:opacity-90" value="removeCause" disabled={causePaneLocked} onClick={event => handleDeleteCause(event, i)}><FontAwesomeIcon icon={faTrash}/> Remove     
									</button>
								</div>
				    		</div>
				    	</div>
			    	</div>
					)
				);
			}
		else{
			return(<div></div>)
		}
	}
 

	return (

		<div className="flex flex-col h-full">
			<HeaderImage server={server_url} source={`${orgData.theme != "" ? orgData.theme : "Header_1.png"}`} />

			<div className = "mt-3 w-11/12 mx-auto">
				<img className = "h-14 mt-2 mb-3 text-center mx-auto" src={icon} alt="Icon" />
				<h1 className = "text-3xl text-center font-bold mb-1">Account Settings</h1>
		    	<hr className="my-4 mx-auto"/>

			    {/*Login Information*/}
			    <div className="hidden">
					<h1 className = "text-xl text-center font-bold mb-1 mt-3">Login Information</h1>
					<h1 className = "text-md mt-6 font-semibold mb-1 disable">Email Address</h1>
				    <input type="text" name="emailBox" id="emailBox" className=" focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={loginPaneLocked}>
			    	</input>

					<h1 className = "text-md mt-6 font-semibold mb-1">Set New Password</h1>
				    <input type="password" name="passwordBox" id="passwordBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={loginPaneLocked}>
			    	</input>

					<div className="mt-4 flex justify-end">
						<button className={`rounded-md text-center ${loginPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
	          			onClick={() => { 

	          				setLoginPaneLocked(!loginPaneLocked);
	      				}}

						>{loginPaneLocked ? "Edit" :  "Cancel"} 
						</button>
						<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" disabled={loginPaneLocked} onClick={handleSave}>Save  
						</button>
					</div> 

			    	<hr className="my-4 mx-auto"/>
		    	</div>

			    {/*Masjid Information*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Information</h1>
				<h1 className = "text-md mt-6 font-semibold mb-1" >Masjid ID</h1>
			    <input type="text" name="idBox" id="idBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled value={user.org.toLowerCase()}
>
		    	</input>

				<h1 className = "text-md mt-6 font-semibold mb-1" >Masjid Display Name</h1>
			    <input type="text" name="displayBox" id="displayBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md  rounded-sm disabled:opacity-50" disabled={infoPaneLocked} value={orgData.masjidDisplayName}  onChange={(e) => setOrgData(orgData => ({...orgData, "masjidDisplayName": e.target.value}))}>
		    	</input>

				<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${infoPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setInfoPaneLocked(!infoPaneLocked);
      				}}

					>{infoPaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveInfoPane" disabled={infoPaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 


		    	<hr className="my-4 mx-auto"/>

			    {/*Masjid Welcome Message*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Welcome Message</h1>
				<h1 className = "text-md mt-6 font-semibold mb-1" >Headline</h1>
			    <input type="text" name="headlineBox" id="headlineBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={welcomePaneLocked} value={orgData.headline} onChange={(e) => setOrgData(orgData => ({...orgData, "headline": e.target.value}))}>
		    	</input>

				<h1 className = "text-md mt-6 font-semibold mb-1" >Message</h1>
			    <textarea type="text" rows="6" name="messageBox" id="messageBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md  rounded-sm disabled:opacity-50" disabled={welcomePaneLocked} value={orgData.welcomeMessage} onChange={(e) => setOrgData(orgData => ({...orgData, "welcomeMessage": e.target.value}))}>
		    	</textarea>

				<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${welcomePaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setWelcomePaneLocked(!welcomePaneLocked);
      				}}

					>{welcomePaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveWelcomePane" disabled={welcomePaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 

		    	<hr className="my-4 mx-auto"/>

			    {/*Masjid Theme*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Theme</h1>
				<h1 className = "text-md mt-6 font-semibold mb-1" >Theme Banner</h1>
			    <select type="text" rows="2" name="themeBox" id="themeBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md  rounded-sm disabled:opacity-50" disabled={themePaneLocked} value={orgData.theme} onChange={themeSelection} >
				    <option value="Header_1.png">Theme 1</option>
				    <option value="Header_2.png">Theme 2</option>
				    <option value="Header_3.png">Theme 3</option>
				    <option value="Header_4.png">Theme 4</option>
				    <option value="Header_5.png">Theme 5</option>
				    <option value="Header_6.png">Theme 6</option>
				    <option value="Header_7.png">Theme 7</option>
				    <option value="Header_8.png">Theme 8</option>
				    <option value="Header_9.png">Theme 9</option>
				    <option value="Header_10.png">Theme 10</option>
				    <option value="Header_11.png">Theme 11</option>
				    <option value="Header_12.png">Theme 12</option>
				    <option value="Header_13.png">Theme 13</option>
				    <option value="Header_14.png">Theme 14</option>
				    <option value="Header_15.png">Theme 15</option>
		    	</select>

				<h1 className = "text-md mt-6 font-semibold mb-1">Preview</h1>
				<img src={themePreview} alt="Theme Preview" />

				<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${themePaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setThemePaneLocked(!themePaneLocked);
      				}}

					>{themePaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveThemePane" disabled={themePaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 

		    	<hr className="my-4 mx-auto"/>


			    {/*Masjid Donation Levels*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Donation Levels</h1>
				<h1 className = "text-md mt-6 font-semibold mb-1" htmlFor="minimumBox">Minimum Donation</h1>
			    <input type="number" name="minimumBox" id="minimumBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={donationPaneLocked} value={orgData.minimumDonation} onChange={(e) => setOrgData(orgData => ({...orgData, "minimumDonation": e.target.value}))}>
		    	</input>

				<h1 className = "text-md mt-6 font-semibold mb-1" htmlFor="suggestedBox">Suggested Donation</h1>
			    <input type="number" name="suggestedBox" id="suggestedBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md  rounded-sm disabled:opacity-50" disabled={donationPaneLocked} value={orgData.suggestedDonation} onChange={(e) => setOrgData(orgData => ({...orgData, "suggestedDonation": e.target.value}))}>
		    	</input>

				<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${donationPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setDonationPaneLocked(!donationPaneLocked);
      				}}

					>{donationPaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveDonationPane" disabled={donationPaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 
		    	<hr className="my-4 mx-auto"/>

			    {/*Masjid Features */}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Features</h1>

				<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="goalFeatureBtn" name="goalFeatureBtn" disabled={featuresPaneLocked} checked={orgData.goalFeatureEnabled} onChange={(e) => setOrgData(orgData => ({...orgData, "goalFeatureEnabled": e.target.checked}))} />
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="goalFeatureBtn">Goal Setting</label>
			      	<p className="opacity-50 text-sm">Set visible goals for donors to track donation progress. Disabling this setting will hide the goal feature completely.</p>
		      	</div>

		      	<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="donationFeatureBtn" name="donationFeatureBtn" disabled={featuresPaneLocked} checked={orgData.donationFeedEnabled} onChange={(e) => setOrgData(orgData => ({...orgData, "donationFeedEnabled": e.target.checked}))}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="donationFeatureBtn">Donation Feed</label>
			      	<p className="opacity-50 text-sm">Display periodic notifications of anonymous donations received to encourage greater giving. Disabling this setting will hide these notifications completely.</p>
		      	</div>

		      	{/* <div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="emailFeatureBtn" name="emailFeatureBtn" disabled={featuresPaneLocked} checked={orgData.receiptFeatureEnabled} onChange={(e) => setOrgData(orgData => ({...orgData, "receiptFeatureEnabled": e.target.checked}))}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="emailFeatureBtn">Automated Email Receipts</label>
			      	<p className="opacity-50 text-sm">Allow GiveMasjid to automatically send donors a receipt confirming their donation for tax-deduction purposes. Disabling this setting prevents these emails from being sent completely.</p>
		      	</div> */}

		      	<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="causeFeatureBtn" name="causeFeatureBtn" disabled={featuresPaneLocked} checked={orgData.causeFeatureEnabled} onChange={(e) => {
			     		setCausePaneToggle(!causePaneToggle);
			     		setOrgData(orgData => ({...orgData, "causeFeatureEnabled": e.target.checked}))}}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="causeFeatureBtn">Specify Donation Causes</label>
			      	<p className="opacity-50 text-sm">Allow donors to designate the purpose of their donation from a pre-existing set of donation causes. Disabling this setting provides donors with only the option of making a general donation.</p>
		      	</div>

		      	<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="subscriptionFeatureBtn" name="subscriptionFeatureBtn" disabled={featuresPaneLocked} checked={orgData.subscriptionFeatureEnabled} onChange={(e) => {
			     		setOrgData(orgData => ({...orgData, "subscriptionFeatureEnabled": e.target.checked}))}}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="subscriptionFeatureBtn">Recurring Donations</label>
			      	<p className="opacity-50 text-sm">Offer donors the ability to schedule recurring donations on a weekly, monthly, or annual frequency.</p>
		      	</div>

		      	<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="islamicHolidayBtn" name="islamicHolidayBtn" disabled={featuresPaneLocked} checked={orgData.islamicHolidayFeatureEnabled} onChange={(e) => {
			     		setOrgData(orgData => ({...orgData, "islamicHolidayFeatureEnabled": e.target.checked}))}}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="islamicHolidayBtn">Islamic Holidays</label>
			      	<p className="opacity-50 text-sm">Rotates special donation buttons to commemorate Islamic Holidays like Ramadan, Eid Al-Fitr, and the Last 10 Days of Dhul-Hijjah.</p>
		      	</div>

		      	<div className={`mt-6 ${featuresPaneLocked ? "opacity-50" : ""}`}>
			     	<input type="checkbox" id="islamicSchoolBtn" name="islamicSchoolBtn" disabled={featuresPaneLocked} checked={orgData.schoolFeatureEnabled} onChange={(e) => {
			     		setOrgData(orgData => ({...orgData, "schoolFeatureEnabled": e.target.checked}))}}/>
			      	<label className="font-semibold text-lg mx-3 text-center" htmlFor="islamicSchoolBtn">School Payment Collection</label>
			      	<p className="opacity-50 text-sm">Run a scheduled enrollment prompt to collect payments for Islamic School Programs. Payments can be collected upfront or monthly.</p>
		      	</div>

				<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${featuresPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setFeaturesPaneLocked(!featuresPaneLocked);
      				}}

					>{featuresPaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveFeaturesPane" disabled={featuresPaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 
		    	<hr className="my-4 mx-auto"/>

			    {/*Masjid Goal Setting*/}
			    <div className={` ${orgData.goalFeatureEnabled ? "" : "hidden" }`}>
					<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Goal Setting</h1>

					<h1 className = "text-md mt-6 font-semibold mb-1">Currently Raised</h1>
				    <input type="number" name="goalBox" id="goalBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={goalsPaneLocked} value={orgData.currentlyRaised} onChange={(e) => setOrgData(orgData => ({...orgData, "currentlyRaised": e.target.value}))}>
			    	</input>

					<h1 className = "text-md mt-6 font-semibold mb-1">Target Goal</h1>
				    <input type="number" name="goalBox" id="goalBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={goalsPaneLocked} value={orgData.targetGoal} onChange={(e) => setOrgData(orgData => ({...orgData, "targetGoal": e.target.value}))}>
			    	</input>

					<h1 className = "text-md mt-6 font-semibold mb-1">Goal Frequency</h1>
				    <select type="text" name="frequencyBox" id="frequencyBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={goalsPaneLocked} value={orgData.goalFrequency} onChange = {(e) => setOrgData(orgData => ({...orgData, "goalFrequency": e.target.value}))}
>
				      <option value="Every Week">Every Week</option>
					  <option value="Every Two Weeks">Every Two Weeks</option>
					  <option value="Every Three Weeks">Every Three Weeks</option>
				      <option value="Every Month">Every Month</option>
					  <option value="Every Two Months">Every Two Months</option>
					  <option value="Every Three Months">Every Three Months</option>
				      <option value="Every Six Months">Every Six Months</option>
					  <option value="Every Year">Every Year</option>
			    	</select>

					<h1 className = "text-md mt-6 font-semibold mb-1">Next Goal Update</h1>
				    <input type="date" name="nextBox" id="nextBox" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50" disabled={goalsPaneLocked} value={orgData.nextGoalUpdate} onChange = {(e) => { 
						setOrgData(orgData => ({...orgData, "nextGoalUpdate": e.target.value}))}}>
			    	</input>

			    	<div>
			    		<p className="text-sm opacity-50 mt-3">Using the above settings to manually adjust the displayed amount raised and the current goal. By specifying frequency, you can adjust the cadence in which the platform will automatically reset your goal. Modifying the date Next Goal Update lets you manually change when the next goal reset will take place.</p>
			    	</div>

					<div className="mt-4 flex justify-end">
						<button className={`rounded-md text-center ${goalsPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
	          			onClick={() => { 

	          				setGoalsPaneLocked(!goalsPaneLocked);

	          				// Quickly run the goal update script to reflect this click
	          				axios
							  .get('https://donate.givemasjid.com/goalupdate')
							  .then(res => {
							    // console.log(`statusCode: ${res.status}`);
							    // console.log(res);
							  })
							  .catch(error => {
							    console.error(error);
							  });

	      				}}

						>{goalsPaneLocked ? "Edit" :  "Cancel"} 
						</button>
						<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveGoalsPane" disabled={goalsPaneLocked} onClick={handleSave}>Save  
						</button>
					</div> 
			    	<hr className="my-4"/>
				</div>

			    {/*Designation Feature Setting*/}
			    <div className={` ${orgData.causeFeatureEnabled ? "" : "hidden" }`}>
					<h1 className = "text-xl text-center font-bold mb-1 mt-3">Donation Designations</h1>

					{renderCauseButtons()}

					<div className="mt-4 flex justify-end">
						<button className={`rounded-md text-center ${causePaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
	          			onClick={() => { 

	          				setCausePaneLocked(!causePaneLocked);

	          				// Quickly run the goal update script to reflect this click
	          				axios
							  .get('https://donate.givemasjid.com/goalupdate')
							  .then(res => {
							    // console.log(`statusCode: ${res.status}`);
							    // console.log(res);
							  })
							  .catch(error => {
							    console.error(error);
							  });

	      				}}

						>{causePaneLocked ? "Edit" :  "Cancel"} 
						</button>
						<button className={`rounded-md text-center ${causePaneLocked ? "hidden" : "bg-transparent border border-gray-300 hover:bg-orange-200 active:bg-orange-200 text-black"}  text-base px-6 py-2 mx-1`} disabled={causePaneLocked} value="handleAddCause" onClick={handleAddCause}><FontAwesomeIcon icon={faPlus}/> Add</button>

						<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveCausePane" disabled={causePaneLocked} onClick={handleSave}>Save  
						</button>
					</div> 
			    	<hr className="my-4"/>
				</div>

				{/* Islamic School Feature */}
				<div className={` ${orgData.schoolFeatureEnabled ? "" : "hidden" }`}>
					<h1 className="text-xl text-center font-bold mb-1 mt-3">School Payment Collection</h1>

					<h1 className = "text-md mt-6 font-semibold mb-1" htmlFor="">School Program Name</h1>
					<h1 className="text-md mt-1 font-semibold mb-1" htmlFor="schoolName"></h1>
					<input
						type="text"
						name="schoolName"
						id="schoolName"
						className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50"
						disabled={schoolPaneLocked}
						value={orgData.schoolName}
						onChange={(e) => setOrgData(orgData => ({...orgData, "schoolName": e.target.value}))}
					/>

					<div className="grid grid-cols-2 gap-4 mt-6">
						<div>
							<h1 className="text-md font-semibold mb-1" htmlFor="enrollmentStartDate">Enrollment Start Date</h1>
							<input
								type="date"
								name="enrollmentStartDate"
								id="enrollmentStartDate"
								className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50"
								disabled={schoolPaneLocked}
								value={orgData.enrollmentStartDate || ''}
								onChange={(e) => setOrgData(orgData => ({...orgData, "enrollmentStartDate": e.target.value}))}
							/>
						</div>
						<div>
							<h1 className="text-md font-semibold mb-1" htmlFor="enrollmentEndDate">Enrollment End Date</h1>
							<input
								type="date"
								name="enrollmentEndDate"
								id="enrollmentEndDate"
								className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50"
								disabled={schoolPaneLocked}
								value={orgData.enrollmentEndDate || ''}
								onChange={(e) => setOrgData(orgData => ({...orgData, "enrollmentEndDate": e.target.value}))}
							/>
						</div>
					</div>

					<h1 className="text-md mt-6 font-semibold mb-3">Enrollment Pricing</h1>
					
					<div className="mb-4">
					<div className="grid grid-cols-3 gap-2 mb-4 ml-1">
						<div className="">Children</div>
						<div className="">Upfront Payment</div>
						<div className="">Monthly Plan</div>
					</div>
					{[0, 1, 2, 3].map(childCount => (
						<div key={`pricing-${childCount}`} className="grid grid-cols-3 gap-4 mb-2 ml-1 ">
						<div className="flex items-center">
							{childCount + 1} {childCount === 1 ? 'Child' : 'Children'}
						</div>
						<input
							type="text"
							className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50"
							disabled={schoolPaneLocked}
							value={orgData.oneTimeSchoolFees?.[childCount] ? `$${parseFloat(orgData.oneTimeSchoolFees[childCount]).toFixed(0)}` : ''}
							onChange={(e) => {
							const value = e.target.value.replace(/[^0-9.]/g, '');
							setOrgData(orgData => ({
								...orgData,
								oneTimeSchoolFees: {
								...orgData.oneTimeSchoolFees,
								[childCount]: value
								}
							}));
							}}
						/>
						<input
							type="text"
							className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-md rounded-sm disabled:opacity-50"
							disabled={schoolPaneLocked}
							value={orgData.monthlySchoolFees?.[childCount] ? `$${parseFloat(orgData.monthlySchoolFees[childCount]).toFixed(0)}` : ''}
							onChange={(e) => {
							const value = e.target.value.replace(/[^0-9.]/g, '');
							setOrgData(orgData => ({
								...orgData,
								monthlySchoolFees: {
								...orgData.monthlySchoolFees,
								[childCount]: value
								}
							}));
							}}
						/>
						</div>
					))}
					</div>

					<p className="opacity-50 text-sm mb-3">List your desired enrollment pricing based on number of enrollments in the table below. In each box, specify the total price for the checkout. For example, if you charge $200 per child for 2 children, specify $400 in the appropriate box.</p>

					<div className="mt-4 flex justify-end">
					<button className={`rounded-md text-center ${schoolPaneLocked ? "bg-transparent border border-gray-300 hover:bg-gray-100 active:bg-gray-100 text-black" : "bg-transparent text-black  border-slate-300"}  text-base px-6 py-2`}
          			onClick={() => { 

          				setSchoolPaneLocked(!schoolPaneLocked);
      				}}

					>{schoolPaneLocked ? "Edit" :  "Cancel"} 
					</button>
					<button className="rounded-md border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base px-6 py-2 ml-2 disabled:bg-gray-400 disabled:opacity-90" value="saveSchoolPane" disabled={schoolPaneLocked} onClick={handleSave}>Save  
					</button>
				</div> 

					<div className="mt-6">

				</div>


					<hr className="my-4"/>
				</div>

			    {/*Visit Org Page*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Masjid Donation Page</h1>
				<div className="mx-auto flex justify-center">
					<Link reloadDocument to={"../" + user.org.toLowerCase()} ><button className="mt-3 block w-full rounded-full border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-lg px-6 py-2 disabled:bg-gray-400 disabled:opacity-90"
					>{"donate.givemasjid.com/" + user.org.toLowerCase()}
					</button></Link>
				</div>

		    	<hr className="my-4 mx-auto"/>

			    {/*Support Email*/}
				<h1 className = "text-xl text-center font-bold mb-1 mt-3">Have a Support Issue?</h1>
				<h2 className = "text-lg opacity-50 text-center mb-1 mt-3">Check out our <a className="font-bold" href="https://docs.givemasjid.com/en/">Help Docs</a> or send an email to us at <a href="mailto:support@givemasjid.com">support@givemasjid.com</a></h2>

			</div>

	    	{/*Page Footer*/}			
	    	{/* <hr className="mt-4 mb-2"/>		 */}
			<Footer/>

		</div>

	)
}


// Export Component
export default MasjidManagerPage;



