// --------------------
// recurringDonation.js |
// --------------------
// This component enables users to make recurring donations.  
// ========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect}  from 'react'
import { Switch } from '@headlessui/react'
import { RadioGroup } from '@headlessui/react'
import { useOutletContext } from "react-router-dom";
import axios from 'axios';

// COMPONENT
// ========================================
function RecurringDonation(params){


    // Initial Variables
    const donationCounts = [
        { name: "1", value: "1" },
        { name: "2" , value: "2" },
        { name: "3" , value: "3" },
        { name: "4" , value: "4" },
        { name: "5" , value: "5" },
        { name: "6" , value: "6" },
        { name: "7" , value: "7" },
        { name: "8" , value: "8" },
        { name: "9" , value: "9" },
        { name: "10", value: "10" },
        { name: "11", value: "11" },
        { name: "12", value: "12" },        
        { name: "13" , value: "13" },
        { name: "14" , value: "14" },
        { name: "15" , value: "15" },
        { name: "16", value: "16" },
        { name: "17", value: "17" },
        { name: "18", value: "18" },
        { name: "19" , value: "19" },
        { name: "20", value: "20" },
        { name: "21", value: "21" },
        { name: "22", value: "22" },
        { name: "23", value: "23" },
        { name: "24", value: "24" },
        { name: "25" , value: "25" },
        { name: "26", value: "26" },
        { name: "27", value: "27" },
        { name: "28", value: "28" },
        { name: "29", value: "29" },
        { name: "30", value: "30" },
      ]


	// Context Data for tracking the session and server interactions 
    const outletContext = useOutletContext();
    const orgData = outletContext.orgData;
    const [donationCount, setDonationCount] = useState(donationCounts[2])

    // Helper Function for Class Names
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // Scroll to display the count setting if the user selects a recurring donation
	useEffect(() => {
        if(params.subscriptionDonation){
            window.scrollBy(0,120);
        }
    }, [params.subscriptionDonation]);

    useEffect(() => {
    if(params.toggleSubscriptionCount){
        window.scrollBy(0,60);
    }
}, [params.toggleSubscriptionCount]);

	// Reset the Donation Count and Toggle if User De-Selects Recurring Donation 
	useEffect(() => {

		if(!params.subscriptionDonation || params.subscriptionDonation === "false" ) {
			params.setToggleSubscriptionCount(false);
		}

	}, [params.subscriptionDonation]);

    // Display the Subscription Donation Page
    return (

		<div className="px-5">
            <div>
                {/* Specify Subscription Donation */}
                <div className={`${orgData.account_features.subscription ? "" : "hidden"}`}>
                    <hr className="my-5" />

                    <div className="flex justify-center 3xl:justify-start 3xl:-ml-5">

                        <Switch.Group as="div" className="flex items-center">
                            <Switch.Label as="span" className="mr-3 text-sm">
                                <span className="text-base font-bold 3xl:text-xl 3xl:text-left 3xl:ml-6">Make Recurring Donation</span>{' '}
                            </Switch.Label>

                            <Switch
                                id="subscriptionDonationToggle"
                                checked={params.subscriptionDonation}
                                onChange={params.setSubscriptionDonation}
                                className={classNames( 
                                    params.subscriptionDonation ? 'bg-violet-400' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-violet-400 focus:ring-offset-2'
                                )}
                            >
                                <span
                                aria-hidden="true"
                                className={classNames(
                                    params.subscriptionDonation ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                                />
                            </Switch>
                        </Switch.Group>

                    </div>
                </div>

                {/* Specify Donation Frequency */}
                <div className={`${params.subscriptionDonation ? "mt-3" : "hidden"}`}>

                    <RadioGroup value={params.subscriptionFrequency} onChange={params.setSubscriptionFrequency} className="mt-2">
                        <div className="grid grid-cols-4 gap-3 sm:grid-cols-4 mx-3">
                            <RadioGroup.Option
                                id="dailySubscription"
                                key="Daily"
                                value="day"
                                className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                    checked
                                        ? 'bg-violet-400 text-white hover:bg-violet-500'
                                        : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-full 3xl:rounded-2xl py-3 3xl:py-6 px-3 text-sm 3xl:text-lg font-semibold sm:flex-1'
                                    )
                                }
                                >
                                <RadioGroup.Label as="span">Daily</RadioGroup.Label>
                            </RadioGroup.Option>
                            <RadioGroup.Option
                                id="weeklySubscription"
                                key="Weekly"
                                value="week"
                                className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                    checked
                                        ? 'bg-violet-400 text-white hover:bg-violet-500'
                                        : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-full 3xl:rounded-2xl py-3 3xl:py-6 px-3 text-sm 3xl:text-lg font-semibold sm:flex-1'
                                    )
                                }
                                >
                                <RadioGroup.Label as="span">Weekly</RadioGroup.Label>
                            </RadioGroup.Option>
                            <RadioGroup.Option
                                id="monthlySubscription"
                                key="Monthly"
                                value="month"
                                className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                    checked
                                        ? 'bg-violet-400 text-white hover:bg-violet-500'
                                        : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-full 3xl:rounded-2xl py-3 3xl:py-6 px-3 text-sm 3xl:text-lg font-semibold sm:flex-1'
                                    )
                                }
                                >
                                <RadioGroup.Label as="span">Monthly</RadioGroup.Label>
                            </RadioGroup.Option>
                            <RadioGroup.Option
                                id="yearlySubscription"
                                key="Yearly"
                                value="year"
                                className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                    checked
                                        ? 'bg-violet-400 text-white hover:bg-violet-500'
                                        : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-full 3xl:rounded-2xl py-3 3xl:py-6 px-3 text-sm 3xl:text-lg font-semibold sm:flex-1'
                                    )
                                }
                                >
                                <RadioGroup.Label as="span">Yearly</RadioGroup.Label>
                            </RadioGroup.Option>
                        </div>
                    </RadioGroup>

                </div>
            </div>

            <div>
                {/* Specify Donation Count */}
                <div className={`${params.subscriptionDonation ? "" : "hidden"}`}>
                    <hr className="my-5" />

                    <div className="flex justify-center 3xl:justify-start 3xl:mb-2">

                        <Switch.Group as="div" className="flex items-center">
                            <Switch.Label as="span" className="mr-3 text-sm">
                                <span className="text-base font-bold 3xl:text-xl">Specify Number of Donations</span>{' '}
                            </Switch.Label>

                            <Switch
                                id="subscriptionCountToggle"
                                checked={params.toggleSubscriptionCount}
                                onChange={params.setToggleSubscriptionCount}
                                className={classNames( 
                                    params.toggleSubscriptionCount ? 'bg-violet-400' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-violet-400 focus:ring-offset-2'
                                )}
                            >
                                <span
                                aria-hidden="true"
                                className={classNames(
                                    params.toggleSubscriptionCount ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                                />
                            </Switch>
                        </Switch.Group>

                    </div>

                </div>

                {/* Donation Counts */}
                <div className={`${params.toggleSubscriptionCount ? "mt-3" : "hidden"}`}>

                <RadioGroup value={params.subscriptionCount} onChange={params.setSubscriptionCount} className="mt-2">
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                    {donationCounts.map((option) => (
                        <RadioGroup.Option
                            id = {"btnCount-" + option.name}
                            key={option.name}
                            value={option.value}
                            className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                        checked
                                            ? 'bg-violet-400 text-white hover:bg-violet-500'
                                            : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                        'flex items-center justify-center rounded-full py-3 3xl:py-4 px-3 text-xs 3xl:text-base font-semibold sm:flex-1'
                                        )
                            }
                            >
                            <RadioGroup.Label as="span">{option.value}</RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                    </div>
                            
                    <div className="mt-3">
                        <RadioGroup.Option
                                key="Until Stopped"
                                value="until stopped"
                                className={({ active, checked }) =>
                                    classNames('cursor-pointer focus:outline-none drop-shadow-md',
                                    checked
                                        ? 'bg-violet-400 text-white hover:bg-violet-500'
                                        : 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-full py-3 3xl:py-4 px-3 text-xs 3xl:text-base font-semibold sm:flex-1'
                                    )
                                }
                                >
                                <RadioGroup.Label as="span">Until Stopped</RadioGroup.Label>
                        </RadioGroup.Option>
                    </div>
                </RadioGroup>

                </div>

            </div>
        </div>   
    );

}

// EXPORT COMPONENT
// ========================================
export default RecurringDonation;