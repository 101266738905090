// --------------------
// footer.js |
// --------------------
// Generic footer which includes links to company website, terms of service, and privacy policy
// =========================================================================================================================


// DEPENDENCIES
// ========================================
import React, {useState, useEffect}  from 'react'
import {Link, useLocation} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


// FOOTER COMPONENT
// ========================================
function Footer() {

 	const { isAuthenticated } = useAuth0();
  	const { logout } = useAuth0();
	const [logoutEnabled, setLogoutEnabled] = useState(false);

 	let location = useLocation();

  	// Create code around displaying the logout or displaying the 
	useEffect(() => {

		if(location.pathname == "/settings" && isAuthenticated) {
			setLogoutEnabled(true);
			// console.log(logoutEnabled);
		}

		else {
			setLogoutEnabled(false);
			// console.log(logoutEnabled);
		}

	}, [location]);

  	const handleLogout = () => {
    	logout();
  	};

	return(
		<div id="footer">

			<hr className="my-4 w-11/12 mx-auto lg:my-3 "/>

			<p className="text-xs text-stone-400 font-thin text-center 3xl:text-left 3xl:ml-7 pb-2"><span className="hidden 3xl:inline">Powered by </span><a href="https://GiveMasjid.com">GiveMasjid</a> - <a href="https://app.termly.io/document/terms-of-use-for-website/fa7aba0d-934e-48b1-874f-ce4d3865b5ab"> Terms of Use</a> - <a href="https://app.termly.io/document/privacy-policy/ce85fd8e-dadb-4ea5-9bea-e7d16dc57ab1"> Privacy </a> - <a href="mailto:support@givemasjid.com"> Support </a>  <Link to="/" className={`${logoutEnabled ? "" : "hidden"}`} onClick={handleLogout}> - Logout </Link>  <Link to="/settings" className={`${logoutEnabled ? "hidden" : ""}`}> - Settings </Link></p>
		</div>
	)
}


// Export Component
export default Footer;