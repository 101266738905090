// -----------------------
// islamicSchoolPage.js |
// -----------------------
// Page controls the UI associated with a user's preference of entering a donation amount manually.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect, useRef} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { RadioGroup } from '@headlessui/react'
import axios from 'axios';
const { DateTime } = require("luxon");


// COMPONENTS
// ========================================
// import RecurringDonation from '../sub-components/recurringDonations.jsx';

// ICONS
// ========================================
// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons'

// import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
// import { faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons'


// COMPONENT
// ========================================
function IslamicSchool (params) {

	// School Variables
	const [studentCount, setStudentCount] = useState("1");
	const [paymentMethod, setPaymentMethod] = useState("One-Time Payment");
	const [isSubscription, setIsSubscription] =useState(false);
	const [registrationPrice, setRegistrationPrice] = useState(0);
	const [endDate, setEndDate] = useState("");
	const [dateDisplay, setDateDisplay] = useState("");
	const [totalPriceDisplay, setTotalPriceDisplay] = useState("");
	const [proceedToCheckout, setProceedToCheckout] = useState(false);
	const [checkoutCause, setCheckoutCause] = useState("");
	const [goalInclusion, setGoalInclusion] = useState(true);
	const [hasCause, setHasCause] = useState(false);
	const [checkoutEnabled, setCheckoutEnabled] = useState(false);


	// Router Navigation
 	let location = useLocation();
	let navigate = useNavigate();

	// Retrieve the relevant values from context
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const currentDonation = outletContext.currentDonation;
	const setCurrentDonation = outletContext.setCurrentDonation;
	const specifiedCause = outletContext.specifiedCause;
	const setSpecifiedCause = outletContext.setSpecifiedCause;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
	const subscriptionDonation = outletContext.subscriptionDonation;
	const setSubscriptionDonation = outletContext.setSubscriptionDonation;
	const subscriptionFrequency = outletContext.subscriptionFrequency;
	const setSubscriptionFrequency = outletContext.setSubscriptionFrequency;
	const toggleSubscriptionCount = outletContext.toggleSubscriptionCount;
	const terminalMode = outletContext.terminalMode;
	const setToggleSubscriptionCount = outletContext.setToggleSubscriptionCount;
	const subscriptionCount = outletContext.subscriptionCount;
	const setSubscriptionCount = outletContext.setSubscriptionCount
	const phoneCapture = outletContext.phoneCapture;
	const setPhoneCapture = outletContext.setPhoneCapture;
	const studentNames = outletContext.studentNames;
	const setStudentNames = outletContext.setStudentNames;
	const schoolName =outletContext.schoolName;
	const schoolOneTimeDonation = outletContext.schoolOneTimeDonation;
	const schoolMonthlyDonation = outletContext.schoolMonthlyDonation;

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	    minimumFractionDigits: 0,
	});

	// Helper Function for Class Names
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

    // Scroll to display the count setting if the user selects a recurring donation
	useEffect(() => {

		var studentCountElement = studentCount-1

		if(paymentMethod != "Monthly Payments"){
			setRegistrationPrice(schoolOneTimeDonation[studentCountElement]);
			setIsSubscription(false);
		}
		else{
			setRegistrationPrice(schoolMonthlyDonation[studentCountElement]);
			setIsSubscription(true);
		}
    }, [studentCount, paymentMethod]);

	// Cancel Click
	function cancelClick(e) {

		navigate("/" + orgID); 
   }

	// Textchange Capture
   function nameChanges(e) {
		const inputText = e.target.value;
    	const studentNamesArray = inputText.split(/[\n,]+/); // Split on new lines or commas
		setStudentNames(studentNamesArray);

		if(studentNamesArray.length > 0  && studentNamesArray[0] !== ''){
			setCheckoutEnabled(true);
		}
		else{
			setCheckoutEnabled(false);
		}
   }

	// Specify the cause
	useEffect(() => {
		setSpecifiedCause({
			title: {schoolName},
			goalInclusion: false
		})
		setPhoneCapture(true);
	}, [])    
	
	// Connect Registration Price and Payment Method to Donation
	useEffect(() => {
		setCurrentDonation(registrationPrice);

		if(paymentMethod == "Monthly Payments") {
			setSubscriptionDonation(true);
			setToggleSubscriptionCount(true);
			setSubscriptionFrequency("month");
			setSubscriptionCount("4");

			const now = DateTime.now();
			setEndDate(now.plus({months: 4}))
			setDateDisplay("through " + endDate.toLocaleString(DateTime.DATE_MED));
			setTotalPriceDisplay(formatter.format(registrationPrice * subscriptionCount));

		}
		else {
			setSubscriptionDonation(false);
			setSubscriptionCount("1");
		}
	}, [registrationPrice, paymentMethod, ]);    

	// CHECKOUT LANGUAGE FROM HELPFURTHER PAGE #TODO ABSTRACT THIS

	// Checkout Variables
	var currentValue = currentDonation;
	var feeStructure = orgData.account_payment;
	var increaseValue = ((currentValue+(feeStructure.stripe_fee.fixed_fee + feeStructure.application_fee.fixed_fee))/(1-(feeStructure.stripe_fee.percent_fee + feeStructure.application_fee.percent_fee))) - currentValue;
	var appFee = feeStructure.application_fee.fixed_fee + (currentValue * feeStructure.application_fee.percent_fee);
	var stripeFee = feeStructure.stripe_fee.fixed_fee + (currentValue * feeStructure.stripe_fee.fixed_fee)
	var totalFee = (feeStructure.application_fee.percent_fee + feeStructure.stripe_fee.percent_fee) * currentValue + (feeStructure.application_fee.fixed_fee + feeStructure.stripe_fee.fixed_fee)

	// Pass the cause to the subcomponents if it exists 
		useEffect(() => {
		if (typeof specifiedCause.title != "undefined" )  {
			setHasCause(true);
			setCheckoutCause(specifiedCause.title);
			setGoalInclusion(specifiedCause.goalInclusion);
		}
		else {
			setCheckoutCause("General")
			setGoalInclusion(true);
		}
	}, [specifiedCause])

	// Go to Checkout
	// Change the page
	useEffect(() => {

		// Non-Terminal Mode Check-Out 
		if(proceedToCheckout && !terminalMode) {

			var checkoutUrl = serverURL + "/checkout?donation=" + currentDonation + "&orgid=" + orgID + "&stripeid=" + orgData.account_payment.stripe_id + "&appsession=" + sessionID + "&ein=" + orgData.account_info.ein + "&orgname=" + orgData.account_info.display_name + "&af=" + appFee + "&acstatus=" + orgData.account_status + "&cause=" + checkoutCause + "&goalInclusion=" + goalInclusion + "&students=" + studentNames.toString();

			// Check if the org has the weekly goal feature enabled
			if(orgData.account_features.goal_tracker) {
				checkoutUrl = checkoutUrl + "&goalfeature=enabled"
			}

			// Check if the org has the email receipt feature enabled
			if(orgData.account_features.email_receipts) {
				checkoutUrl = checkoutUrl + "&receiptfeature=enabled"
			}

			// Check if the org has the donation feed enabled 
			if(orgData.account_features.donation_feed) {
				checkoutUrl = checkoutUrl + "&feedfeature=enabled"
			}

			// Check if the subscription toggle is active
			if(subscriptionDonation) {
				// checkoutUrl = checkoutUrl + "&subscription=true";
				checkoutUrl = checkoutUrl + "&subscription=true" + "&frequency=" + subscriptionFrequency;
			
				// Check if user has specified a number of donations
				if(toggleSubscriptionCount){
					checkoutUrl = checkoutUrl + "&donationcount=" + subscriptionCount;
				}
			}

			// Check if phone capture is true
			if(phoneCapture) {
				checkoutUrl = checkoutUrl + "&phonecapture=true";
			}
			else{
				checkoutUrl = checkoutUrl + "&phonecapture=false";
			}

			// Navigate to the checkout URL
			window.location.replace(checkoutUrl);

		}

	}, [proceedToCheckout]); 

	return (
		<div>
		
			<div className="px-4">
				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				<h1 className="text-xl font-bold text-center mb-1 3xl:text-2xl">{schoolName} Registration</h1>
				<p className="text-center font-thin 3xl:text-base text-sm">Please fill out the form below to enroll your children for the masjid's upcoming Sunday School Program. Once submitted, you'll be directed to a portal where you can finalize payment. Please include the email and phone number of at least one parent or guardian when submitting. Once we've received your payment, we'll be in touch with next steps, inshallah.</p>

				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				{/* Specify Number of Children  */}
				<p className="text-base font-medium text-center mb-5 3xl:text-lg">How many students would you like to register?</p> 

				<RadioGroup value={studentCount} onChange={setStudentCount} className="mt-2">
					<div className="grid grid-cols-4 gap-2 lg:gap-2 3xl:gap-3">

						<RadioGroup.Option
							id="oneChild"
							key="OneChild"
							value="1"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">1</RadioGroup.Label>
						</RadioGroup.Option>

						<RadioGroup.Option
							id="twoChild"
							key="twoChild"
							value="2"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">2</RadioGroup.Label>
						</RadioGroup.Option>

						<RadioGroup.Option
							id="threeChid"
							key="threeChild"
							value="3"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">3</RadioGroup.Label>
						</RadioGroup.Option>


						<RadioGroup.Option
							id="fourChild"
							key="fourChild"
							value="4"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">4</RadioGroup.Label>
						</RadioGroup.Option>

					</div>
				</RadioGroup>

				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				{/* Specify Children Names*/}
					<p className="text-base font-medium text-center mb-1 3xl:text-lg">What are the student's names?      
					{!checkoutEnabled && ( 
						<span className="inline-flex items-center rounded-full bg-gray-50 px-2 mx-1 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
						Required
						</span>
					)}
					</p> 
				<div>
					<label htmlFor="studdentNames" className="block text-sm font-light text-gray-900 text-center">
						Please include one on each line.
					</label>
					<div className="mt-2">
						<textarea
						id="studdentNames"
						name="studdentNames"
						onChange={nameChanges}
						rows={4}
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base 3xl:text-lg sm:leading-6 resize-none"
						defaultValue={''}
						/>
					</div>
				</div>

				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>



				{/* Specify Payment Option*/}
				<p className="text-base font-medium text-center mb-5 3xl:text-lg">How would you like to pay?</p> 

				<RadioGroup value={paymentMethod} onChange={setPaymentMethod} className="mt-2">
					<div className="grid grid-cols-2 gap-2 lg:gap-2 3xl:gap-3">

						<RadioGroup.Option
							id="upfrontPayment"
							key="upfront"
							value="One-Time Payment"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">One-Time Payment</RadioGroup.Label>
						</RadioGroup.Option>

						<RadioGroup.Option
							id="monthlyPayment"
							key="monthly"
							value="Monthly Payments"
							className={({ active, checked }) =>
								classNames('cursor-pointer focus:outline-none drop-shadow-md',
								checked
									? 'bg-violet-400 text-white hover:bg-violet-500'
									: 'ring-1 ring-inset ring-gray-00 bg-white text-gray-900 hover:bg-gray-50',
								'flex items-center justify-center rounded-full 3xl:rounded-2xl py-4 3xl:py-6 px-3 text-base 3xl:text-lg font-semibold sm:flex-1'
								)
							}
							>
							<RadioGroup.Label as="span">Monthly Payment</RadioGroup.Label>
						</RadioGroup.Option>

					</div>
				</RadioGroup>


				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				{/* Total Registration Dues*/}
				<p className="text-base font-medium text-center mb-3 3xl:text-lg">Registration Fees Due Today</p> 
				<div className="mt-1 relative rounded-md">

					<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					</div>
					<input min="0.00" step="5.00" name="don_input" id="don_input" className="ring-indigo-500 border-2 border-gray-200 block w-full border-gray-300 text-3xl font-extrabold text-center rounded-full py-3 3xl:py-3" value={formatter.format(registrationPrice)}>
					</input>
				</div>

				<div className={`${isSubscription ? "" : "hidden"}`}>
					<p className="text-center font-thin mt-4 mb-4 text-sm italic 3xl:text-base">You will be invoiced for the above amount every 30 days through {dateDisplay}. The total fees across all invoices will be for {totalPriceDisplay}.</p>
				</div>
				
				<hr className="w-11/12 mx-auto my-4 lg:my-5"/>

				{/* Checkout  */}
				<div className="visible">
					<button  

						// component ={Link} to="/Add-On-Donation"
						onClick={() => { 	
							setProceedToCheckout(true);
						}}
						id="chk_btn" className={`rounded-full text-white text-lg mx-auto text-center py-3 mt-5 block w-full drop-shadow-xl 3xl:py-5 3xl:text-xl ${checkoutEnabled ? 'bg-theme-100 hover:bg-theme-200 active:bg-theme-200' : 'bg-gray-400'}`} disabled={!checkoutEnabled}>Continue to Payment
					</button>	

					<button 
						onClick={cancelClick} 
						id="ca_btn" className=" bg-transparent opacity-30 text-black text-xs mx-auto block py-3 px-3 drop-shadow-xl 3xl:text-base"><FontAwesomeIcon icon={faArrowRotateLeft}/> 
						Cancel
					</button>
		
				</div>	


			</div>

		</div>
	)
}


// Export Component
export default IslamicSchool;