// --------------
// appWrapper.js |
// --------------
// Serves as the main top-level wrapper for the react application. Core responsibility is to create a user session in the db, and create the 
// card interface of the overall application. This route also maintains the first use of OutletContext for relaying the server URL and 
// session id. 
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet';
import {useParams, useSearchParams, useLocation, Outlet} from "react-router-dom";
import axios from 'axios';

// Server URL 
const server = require("../../connections/serverURL");
var connection_server = server.getServer();

// COMPONENT
// ========================================
function AppWrapper() {

	// Initial State Variables 
	// sessionID: The identifier in the "sessions" db which allows us to track the user journey.
	// sessionUpdate: A boolean used to indicate that the session has been created in the db
	// serverURL: The location of the server for API requests 
	const [sessionID, setSessionID] = useState("");
	const [sessionUpdate, setSessionUpdate] = useState(false);
	const [serverURL, setServerURL] = useState("");
	const [terminalMode, setTerminalMode] = useState(false);
	const [terminalReader, setTerminalReader] = useState("");
	const [terminalLive, setTerminalLive] = useState("");
	const [deviceType, setDeviceType] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [backgroundImage, setBackgroundImage] = useState("Wallpaper_BG.png");


	// outletData variable for passing along sessionID and server URL to other components
	var outletData = {
		sessionID: sessionID,
		setSessionID: setSessionID,
		serverURL: serverURL,
		terminalMode: terminalMode,
		terminalReader: terminalReader,
		terminalLive: terminalLive,
		deviceType: deviceType,
		isLoading: isLoading,
		setIsLoading: setIsLoading,
		backgroundImage: backgroundImage,
		setBackgroundImage: setBackgroundImage
	}

	// React Router for Path and Parameter Retrieval
	let [searchParams, setSearchParams] = useSearchParams();
  	var pathname = useLocation().pathname;
  	var searchP = useLocation().search;
	
	// Determine if we are running on a mobile device, minimized desktop, or large desktop display
	useEffect(() => {
		const checkDeviceType = () => {
		// Tailwind's default breakpoint for 'lg' is 1024px
		const isDesktop = window.matchMedia('(min-width: 1024px)').matches;
			setDeviceType("large-desktop");
			// console.log("Desktop: " + deviceType)
		};

		checkDeviceType();

		window.addEventListener('resize', checkDeviceType);
		return () => window.removeEventListener('resize', checkDeviceType);
	}, []);

  	// Establish whether we are running in terminal mode
  	useEffect(() => {

  		// Register Terminal Mode if Present
  		if(searchParams.get("terminal")) {
  			setTerminalMode(true);
  			setTerminalReader(searchParams.get("reader"))
  		}

  		if(searchParams.get("live")) {
  			setTerminalLive(true);
  		}

  		// Maintain Terminal Mode in the URL
  		if(terminalMode){
  			setSearchParams("?terminal=true&reader=" + terminalReader + "&live=" + terminalLive)
  		}

  	}, [searchParams])

  	// Side effect for creating a new session in the db. Runs once at the start of the component render.
	useEffect(() => { 

		// Set the connection server URL
		setServerURL(connection_server);

		// TODO: Cut this if the above works...
		// if(!sessionUpdate && sessionID == "" && !(pathname.includes("Donation-Successful")) && !(pathname.includes("Add-On")) && !(pathname.includes("Choose-Donation")) && !(searchP.includes("prev"))) {

		// Only create a new session in the db if one does not already exist and this is not a restored or completed session
		if(!sessionUpdate && sessionID == "" && !(searchP.includes("prev")) && !(pathname.includes("Donation-Successful")) && !(pathname.includes("Page-Not-Found")) ) {

			// Ping the server to log the new session. Once the session has been logged store the resulting sessionID in state and note the update. 
		 	var sessionCreationURL = connection_server + '/sessions/create/?entrypath=' + pathname;

		 	// Function for running session creation.
		 	const createSession = async() => {
		 		const result = await axios(sessionCreationURL);
		 		const data = await result.data;

		 		// Store the sessionID, update status, and connection URL in state
				setSessionID(data);
				setSessionUpdate(true);
		 	};

		 	createSession();
		}

	}, [pathname] )

	// Render Function  
	return(

		// Specify that the app should match the full height of the page.  (removed: )
		<div className="flex h-screen ">
			<div className = "mx-auto lg:m-auto bg-[#FFFFF6] lg:bg-transparent 3xl:flex 3xl:mx-auto 3xl:justify-center 3xl:min-h-[95vh] 3xl:max-h-[80vh]">

				{/*Specify that content should be centered and not exceed the large screen size specified in the tailwind config*/}
			 	{/*Removed */}
				<div id="content" className ={`bg-[#FFFFF6] max-w-screen-lg 3xl:max-w-screen-xl  xl:shadow-zinc-600 lg:my-2 xl:my-5 xl:shadow-xl xl:rounded-3xl  3xl:mt-4 3xl:shadow-2xl 3xl:flex 3xl:max-h-full 3xl:overflow-auto ${isLoading ? "3xl:w-5/5 3xl:rounded-none" : "3xl:w-3/5 3xl:rounded-tl-2xl 3xl:rounded-bl-3xl 3xl:rounded-tr-none 3xl:rounded-br-none 3xl:ml-5 3xl:pl-2"}`}>

					{/*Pass the outlet context with the sessionID and serverURL */}
					<Outlet context={outletData}/>

				</div>

				<div id="image" className={`3xl:w-2/5 hidden 3xl:block 3xl:max-h-[95vh]  ${isLoading ? "3xl-hidden": "3xl"} ` }>
					<img className="h-full 3xl:object-cover py-4 3xl:rounded-tr-3xl 3xl:rounded-br-3xl 3xl:-mt-0.5" src={serverURL + "/images/" +  backgroundImage} alt="Masjid Wallpaper" aria-label="GiveMasjid Logo" />
		        </div>	
			</div>
		</div>
	);	
}


// EXPORT COMPONENT
// ========================================
export default AppWrapper;