// --------------------
// GoalTraker.js |
// --------------------
// Generic component used to display the weekly goal status for each masjid=
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect}  from 'react'
import axios from 'axios';

// Server URL 
const server = require("../../connections/serverURL");
var connection_server = server.getServer();

// HEADER COMPONENT
// ========================================
function GoalTracker(params){

	// State Variables
	const [goalValue, setGoalValue] = useState(999999);
	const [currentValue, setCurrentValue] = useState(0);
	const [percentGoal, setPercentGoal] = useState("0%");
	const [remainingValue, setRemainingValue] = useState(9999999);
	const [newDonation, setNewDonation] = useState(0);
	const [goalAchieved, setGoalAchieved] = useState(false)	

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	    maximumFractionDigits: 0,
	    minimumFractionDigits: 0
	});

	// Calculate the percent raised and the amount remaining to be raised 
	useEffect(() => {
		setPercentGoal(Math.floor(100 * currentValue / goalValue).toString() + "%")
		setRemainingValue(goalValue - currentValue);

		if(remainingValue <=0 ) {
			setGoalAchieved(true);
		}

	}, [goalValue, currentValue, remainingValue]);

	// Retrieve the goal and current value on load
	useEffect(() => {

		if(params.featureEnabled) {

			// Build the URL to call
		 	var goalAPIUrl = connection_server + '/api/currentgoal/?orgid=' + params.orgID; 

		 	// Update Function
		 	const goalData = async() => {
		 		const result = await axios(goalAPIUrl);
		 		setGoalValue(result.data.goal);
		 		setCurrentValue(result.data.raised);
		 	};

		 	// Run the API call
		 	goalData();		

		}

	}, [params.featureEnabled])

	// Test click to raise dollar amounts 
	function handleIncrease(e) {
		
		setNewDonation(Math.floor(Math.random() * (100 - 5 + 1)) + 5);
		setCurrentValue(currentValue + newDonation);
	}

	// Calculate the percentage to set the width to

	if(params.featureEnabled) {
		return (
			<div className="">
				<hr className="mb-3 w-11/12 mx-auto 3xl:mt-2 hidden 3xl:block" />

				<div className={`mt-3 ${params.left ? "": ""}`}>
					<hr className="mb-3 w-11/12 mx-auto 3xl:hidden" />
					<div className={`flex w-full ${params.title ? "": "hidden"} `}>
						<div className="mx-auto"><h4 className="inline font-bold">{params.orgType} Goal</h4></div>
					</div>

					<div className="w-11/12 mx-auto bg-white border border-gray rounded-full h-5 3xl:h-7 my-1 mb-3">
					  <div className="bg-theme-200 rounded-full text-xs 3xl:text-sm font-medium text-white text- leading-none 3xl:leading-4 text-center p-1 " style={{width: percentGoal, maxWidth: "100%"}}>{percentGoal}</div> 
					</div>

					{/*Switch this to the grid and restyle*/}
					<div className="flex w-11/12 3xl:w-9/12 3xl:justify-start justify-center  mx-auto 3xl:mx-4">
						<div className="mx-auto t"><p className="font-base text-sm 3xl:text-base "> <span className="font-semibold">{formatter.format(goalValue)}</span> Goal</p></div>
						<div className="mx-auto"><p className="text-sm"> | </p></div>
						<div className="mx-auto"><p className="font-base text-sm 3xl:text-base"> <span className="font-semibold">{formatter.format(currentValue)}</span> Raised </p></div>
						<div className="mx-auto"><p className="text-sm "> | </p></div>
						<div className="mx-auto">
							<p className={`font-base text-sm 3xl:text-base ${goalAchieved ? "hidden" : ""}`}> <span className="font-semibold"> {formatter.format(remainingValue)}</span> To Go </p>
							<p className={`font-semibold text-sm 3xl:text-base ${goalAchieved ? "" : "hidden"}`}> Goal Achieved! </p>
						</div>
					</div>
				</div>
				{/* <hr className="my-4 w-11/12 mx-auto lg:my-3 "/> */}
			</div>
		)
	}
	else {
		return(<div />)
	}
}


// Export Component
export default GoalTracker;