//=======================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  {faMoon } from '@fortawesome/free-solid-svg-icons'
import {  useNavigate} from "react-router-dom";


// Islamic Holiday Calendar
// ========================================
var moment = require('moment-umm-al-qura');
moment().format('iYYYY/iM/iD');

// Create a variable to capture today's date (Hijri)
const todaysDateHijri = moment();

// Extract the Hijri year today
const todaysYearHijri = todaysDateHijri.iYear(); 

// Create a variable to store all of the Islamic Holidays with their Hirji dates, Gregorian Dates, App Display Dates, Pledge Conditions, and Pledge Amounts

let islamicHolidays = [
    {
        name: "Ashura",
        displayButton: "Ashura Donation",
        displayFooter: "Make a Donation to Commemorate the Day of Ashura",
        iStartDate: moment(todaysYearHijri + " 1 7", 'iYYYY iM iD'),
        iEndDate: moment(todaysYearHijri + " 1 12", 'iYYYY iM iD'),
        displayStartDate: moment(todaysYearHijri + " 1 7", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 1 12", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: false,
        cause: {
            goalInclusion: true,
            pathname: "Ashura",
            title: "Ashura Donation"
        }
    },
    {
        name: "Ramadan",
        displayButton: "Ramadan Pledge",
        displayFooter: "Donate Daily through the End of Ramadan",
        iStartDate: moment(todaysYearHijri + " 9 1", 'iYYYY iM iD'),
        iEndDate: moment(todaysYearHijri + " 9 1", 'iYYYY iM iD').endOf("iMonth"),
        displayStartDate: moment(todaysYearHijri + " 9 1", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 9 29", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: true,
        subscriptionFrequency: "day",
        subscriptionCount: moment(todaysYearHijri + " 9 1", 'iYYYY iM iD').endOf("iMonth").diff(moment(), 'days'),
        cause: {
            goalInclusion: true,
            pathname: "Ramadan-Pledge",
            title: "Ramadan Pledge"
    
        }
    },
    {
        name: "Eid-Al-Fitr",
        displayButton: "Eid Al-Fitr Donation",
        displayFooter: "Support Those in Need with Zakat Al-Fitr",
        iStartDate: moment(todaysYearHijri + " 9 1", 'iYYYY iM iD').endOf("iMonth"),
        iEndDate: moment(todaysYearHijri + " 10 3", 'iYYYY iM iD'),
        displayStartDate: moment(todaysYearHijri + " 9 29", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 10 3", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: false,
        cause: {
            goalInclusion: true,
            pathname: "Eid-Al-Fitr",
            title: "Eid Al-Fitr"
        }
    },
    {
        name: "Dhul Hijjah",
        displayButton: "Dhul Hijjah Pledge",
        displayFooter: "Donate Daily through the First 10 Days of Dhul-Hijjah",
        iStartDate: moment(todaysYearHijri + " 12 1", 'iYYYY iM iD'),
        iEndDate: moment(todaysYearHijri + " 12 10", 'iYYYY iM iD'),
        displayStartDate: moment(todaysYearHijri + " 12 1 ", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 12 8", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: true,
        subscriptionFrequency: "day",
        subscriptionCount: moment(todaysYearHijri + " 12 10", 'iYYYY iM iD').diff(moment(), 'days'),
        cause: {
            goalInclusion: true,
            pathname: "Dhul-Hijjah-Pledge",
            title: "Dhul-Hijjah Pledge"
        }
    },
    {
        name: "Arafat",
        displayButton: "Arafat Donation",
        displayFooter: "Make a Donation to Commemorate the Day of Arafat",
        iStartDate: moment(todaysYearHijri + " 12 9", 'iYYYY iM iD'),
        iEndDate: moment(todaysYearHijri + " 12 10", 'iYYYY iM iD'),
        displayStartDate: moment(todaysYearHijri + " 12 8 ", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 12 11", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: false,
        cause: {
            goalInclusion: true,
            pathname: "Arafat-Donation",
            title: "Arafat Donation"
        }
    },
    {
        name: "Eid-Al-Adha",
        displayButton: "Eid Al-Adha Donation",
        displayFooter: "Make a Donation to Celebrate Eid Al-Adha",
        iStartDate: moment(todaysYearHijri + " 12 10", 'iYYYY iM iD'),
        iEndDate: moment(todaysYearHijri + " 12 13", 'iYYYY iM iD'),
        displayStartDate: moment(todaysYearHijri + " 12 10", 'iYYYY iM iD'),
        displayEndDate: moment(todaysYearHijri + " 12 13", 'iYYYY iM iD'),
        donationAmount: 10,
        subscriptionDonation: false,
        cause: {
            goalInclusion: true,
            pathname: "Eid-Al-Adha",
            title: "Eid Al-Adha Donation"
        }
    },
];

// Holiday Button COMPONENT
// ========================================
function HolidayButton(params){

    // Create a state variable for checking if holiday is active today
    const [ activeHoliday, setActiveHoliday ] = useState({});

    let navigate = useNavigate();

    // Check if any holiday is active 
	useEffect(() => {

        // Loop through each holiday looking for an active holiday
        islamicHolidays.forEach(holiday => {
            
            // Use the current date
            const dateCheck = moment();

            // Test a simulate date 
            // const dateCheck = moment("1444 09 29", 'iYYYY iM iD');

            // Check if today's date is between the start and end date of the holiday
            if (dateCheck.isBetween(holiday.displayStartDate, holiday.displayEndDate, undefined, '[]')) {
                params.setIsHolidayActive(true);
                setActiveHoliday(holiday);
            }
        });

	}, [])

	return (
		<div className = {params.holidayFeatureEnabled ? "" : "hidden"}>
            <button 
                    onClick={() => { 

                        if(activeHoliday.subscriptionDonation || activeHoliday.subscriptionDonation === "true"){
                            params.setSubscriptionDonation(activeHoliday.subscriptionDonation);
                            params.setSubscriptionFrequency(activeHoliday.subscriptionFrequency);
                            params.setToggleSubscriptionCount("true");
                            params.setSubscriptionCount(activeHoliday.subscriptionCount.toString());
                        }
                        params.setCurrentDonation(10)
                        params.setSpecifiedCause(activeHoliday.cause)
                        navigate("../Set-Donation/" + activeHoliday.cause.pathname); 	
                    }}

                    id="holidayBtn" className= {params.isHolidayActive ? "mt-2 rounded-md text-center text-white opacity-100 bg-violet-500 hover:bg-violet-600 active:bg-violet-600 text-xl py-3 font-bold w-full shadow-md shadow-slate-400 leading-3 " : "hidden"}>{activeHoliday.displayButton}<span className="text-xl"><FontAwesomeIcon className="pl-2" icon={faMoon}/></span><br/> <span className="text-sm font-light opacity-70 italic">{activeHoliday.displayFooter}</span> 
                 </button>
		</div>
	)
}


// Export Component
export default HolidayButton;