// ---------
// index.js |
// ---------
// Serves as the main top-level router for the react application. Uses React Router to route the application to each of the key pages in the  
// application flow.   
// =========================================================================================================================================

// DEPENDENCIES 
// ========================================
import React from 'react'
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Navigate
} from "react-router-dom";
import { Auth0ProviderWithConfig } from "./auth0-provider-with-config";
import { ProtectedRoute } from "./components/wrapper-components/protectedRoute.jsx";


// COMPONENTS ("PAGES")
// ========================================
import AppWrapper from './components/wrapper-components/appWrapper.jsx'
import CompanyLandingPage from './components/page-components/companyLandingPage.jsx'
import OrgLandingPage from './components/page-components/orgLandingPage.jsx'
import OrgWrapper from './components/wrapper-components/orgWrapper.jsx'
import DonationWrapper from './components/wrapper-components/donationWrapper.jsx'
import SpecifyCause from './components/page-components/specifyCausePage.jsx'
import SetDonationPage from './components/page-components/setDonationPage.jsx'
import CustomDonationPage from './components/page-components/customDonationPage.jsx'
import IslamicSchoolPage from './components/page-components/islamicSchoolPage.jsx'
import HelpFurtherPage from './components/page-components/helpFurtherPage.jsx'
import DonationSuccessfulPage from './components/page-components/donationSuccessfulPage.jsx'
import LostPage from './components/page-components/lostPage.jsx'
import MasjidManagerPage from './components/page-components/masjidManagerPage.jsx'

// RENDER ("ROUTING")
// ========================================
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(	
	<React.StrictMode>

    <Auth0ProviderWithConfig>

	  	<BrowserRouter>
			<Routes>
	
				{/*App Wrapper -- Route through which all components flow through */}
				<Route path="/" element={<AppWrapper/>}> 

					{/*Company Landing Page -- Route if no organization is specified (e.g. "https://donate.givemasjid.com" ) */}
					<Route index element={<CompanyLandingPage />} />
			
					{/*Masjid Manager Page */}
					<Route path="/settings" element={<ProtectedRoute component={MasjidManagerPage} />} />

					{/*Org Wrapper -- Route if an organization is specified (e.g. "https://donate.givemasjid.com/{organization}/{path}" ) */}
					<Route path="/:orgID" element={<OrgWrapper />}>

						{/*Org Landing Page -- Initial route for when an organization is specified: (e.g. "https://donate.givemasjid.com/{organization}" ) */}
						<Route index element= {<OrgLandingPage />} />

						{/*Donation Application Route -- Route if an organization is specified (e.g. "https://donate.givemasjid.com/{organization}/{path}" ) */}
						<Route path="/:orgID" element={<DonationWrapper />}>

							{/*Specifiy Cause Page  -- Page for displaying a list of options for which the donor can specify their donation designation ((e.g. "https://donate.givemasjid.com/{organization}/{Specify-Cause}" ))}*/}
							<Route path="Specify-Cause" element= {<SpecifyCause />} /> 

							{/*Set Donation Page -- Initial page for selecting donation page using clickable buttons (e.g. "https://donate.givemasjid.com/{organization}/Set-Donation" ) */}
							<Route path="Set-Donation/" element= {<SetDonationPage />} /> 

							{/*Set Donation Page with a Cause Specified -- Duplicate of the Set-Donation Page with the option of including a cause to the URL parameter for easy reference  (e.g. "https://donate.givemasjid.com/{organization}/Set-Donation/{cause}" ) */}
							<Route path="Set-Donation/:cause" element= {<SetDonationPage />} /> 

							{/*Custom Donation Page -- Page for selecting a custom donation if user opts to do so (e.g. "https://donate.givemasjid.com/{organization}/Custom-Donation" ) */}			
							<Route path="Custom-Donation" element={<CustomDonationPage />} />

							{/*Islamic School Page - Page for Registering students for Islamic Schools" ) */}			
							<Route path="Islamic-School" element={<IslamicSchoolPage />} />

							{/*Help Further Page -- Page prompting user to cover processing expenses (e.g. "https://donate.givemasjid.com/{organization}/Help-Further" ) */}			
							<Route path="Help-Further" element={<HelpFurtherPage />} />
						
						</Route>

						{/*Donation Successful Page -- Page following a successful checkout (e.g. "https://donate.givemasjid.com/{organization}/Donation-Successful" ) */}			
						<Route path="Donation-Successful"  element={<DonationSuccessfulPage />} />

						{/*Lost Page */}			
						<Route path="*"  element={<Navigate to="../" replace />} />

					</Route>	

					{/*Lost Page -- Page if a user attempts to visit a path not specified by the application  (e.g. "https://donate.givemasjid.com/{Wrong-URL}" ) */}			
					<Route path="/Page-Not-Found" element=<LostPage />  />

				</Route> 

			</Routes>
	  	</BrowserRouter>
    </Auth0ProviderWithConfig>  	
    
	</React.StrictMode>


	);