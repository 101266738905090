// -----------------------
// companyLandingPage.js |
// -----------------------
// Serves as a backup landing page in case a user decideds to remove the orgid (e.g. donate.givemasjid.com). This provides them a quick 
// snapshot of what GiveMasjid is. It's not meant to be a regularly visited website.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import axios from 'axios';

// COMPONENTS
// ========================================
import BannerImage from "../sub-components/bannerImage.jsx"
import Footer from "../sub-components/footer.jsx"

// ICONS
// ========================================
import icon from '../../assets/mosque.svg'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


// COMPONENT
// ========================================

function BeginPage(params){

	let navigate = useNavigate();
	const outletContext = useOutletContext();
  	const serverURL = outletContext.serverURL;
	const setBackgroundImage = outletContext.setBackgroundImage

	// Scroll to ensure the begin button is viewable
	// useEffect(() => {

	// 	var element = document.getElementById("footer");
	// 	element.scrollIntoView({behavior: "smooth"});

	// })

	// Set the initial background imagfe
	useEffect(() => {
		setBackgroundImage("Wallpaper_H4.png")
	}, [])

	return (

		<div className="flex flex-col h-full">
			<BannerImage source="Banner_H4.png" server={serverURL}/>
			<div className="ml-8 mt-3 flex-grow flex 3xl:justify-center 3xl:items-center">
			
				<div className="3xl:justify-center 3xl:items-center">
					<div className="flex">
						<div>
							<h1 className = "text-3xl mt-8 ">Welcome to</h1>
							<h1 className = "text-4xl font-bold mb-1 pt-3">GiveMasjid</h1>
						</div>
						<img className = "h-16 ml-2 mt-16" src={icon} alt="Icon" />
					</div>

					<hr className = "mr-8 mb-5 mt-2" />
					
					<div className="mr-3 3xl:mr-5">
						<p className = " text-sm font-bold leading-7 3xl:text-lg">We help masjids manage donations with ease. </p>
						<p className = " text-sm leading-7 3xl:text-lg">To begin donating, visit your masjid's donation page or scan their donation QR code.</p>
						<p className = " text-sm leading-7 mt-5 3xl:text-lg"><b>Interested in learning more?</b><br />Visit us at: <a href="https://givemasjid.com">https://givemasjid.com</a></p>
						<p className = " text-sm leading-7 mt-5 3xl:text-lg"><b>Want to get in touch?</b><br />Email us at: <a href="mailto:salam@givemasjid.com">salam@givemasjid.com</a></p>
					</div>
				</div>


			</div>

			<Footer/>
			<div id="scrollpoint" />

		</div>
	)
}


// Export Component
export default BeginPage;



