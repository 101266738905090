// --------------------
// addDonation.js |
// --------------------
// Sub-component used to control the add donation buttons on the main set-donation page.
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import axios from 'axios';


// Font Awesome Icons
// ========================================
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// ADD DONATION COMPONENT
// ========================================
function AddDonation(params){

	// Context Data for tracking the session and server interactions 
  	const outletContext = useOutletContext();
   	const sessionID = outletContext.sessionID;
  	const serverURL = outletContext.serverURL;
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;

	// Router Navigation
 	let location = useLocation();
	let navigate = useNavigate();

  	// Donation Click Handler
	function handleDonationClick(e,) {
		var currentValue = params.currentDonation;
		var donationValue = e.target.value;
		
		// Capture all donation buttons clicked 
		var dbHolder = params.dbsClicked;
		dbHolder.push(donationValue);
		params.setdbsClicked(dbHolder);
		params.setCurrentDonation(parseInt(currentValue) + parseInt(donationValue))

		if(params.dbsClicked != "" ) {

			// Create Session
		 	var sessionUpdateURL = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.donation_buttons_clicked&updatevalue=" + params.dbsClicked;

		 	// Store Results of API Call in Org Data and Set Loader to False
		 	const fetchData = async() => {
		 		const result = await axios(sessionUpdateURL);
		 	};

		 	// Run the Axios API Call and Track Whether Successful 
		 	fetchData();			
		}

	}

	// Clear Click Handler
	function handleClearClick(e) {
		params.setCurrentDonation(0)

		// Create Session
	 	var sessionUpdateURL = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.clicked_clear&updatevalue=true";

	 	// Capture clear button clicked 
		var dbHolder = params.dbsClicked;
		dbHolder.push(0);
		params.setdbsClicked(dbHolder);

	 	// Store Results of API Call in Org Data and Set Loader to False
	 	const fetchData = async() => {
	 		const result = await axios(sessionUpdateURL);
	 	};

	 	// Run the Axios API Call and Track Whether Successful 
	 	fetchData();		

	}

	// Button Renderer 
	function renderButtons() {
		if(params.donationMenu){	
			return( 				
				params.donationMenu.map((amount, i) => <button key={i} id={"db_btn_" + i} value = {amount} onClick = {handleDonationClick} className="transition-all duration-75 rounded-2xl text-lg text-black font-bold py-4 3xl:py-7 3xl:text-2xl drop-shadow-xl opacity-70 border-4 focus:opacity-100 focus:grayscale-0 hover:border-theme-100 active:border-theme-200 active:opacity-100 bg-slate-100 active:text-xl active:drop-shadow-sm active:scale-90" >${amount}</button>)
				);
			}
		else{
			return(<div></div>)
		}
	}

	// Helper Function for Class Names
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

	// Display the Add Donation Page
	return (
		<div className="mb-3 px-5">
				<h2 className="text-base font-bold text-center mb-1 3xl:text-xl 3xl:mb-2 3xl:text-left 3xl:ml-1">Add to Donation</h2>

				<div className="grid grid-cols-4 gap-3 lg:gap-4 3xl:gap-6">
					{renderButtons()}
				</div>
				<div className="grid grid-cols-2 gap-3 lg:gap-4 mt-4">
					<button           		
						id="cus_btn" onClick={() => { 
	          				navigate("/" + orgID + "/Custom-Donation/" + (params.hasCause ? "?cause=" + params.cause.pathname : "")); 
	          			}}

          				className="rounded-2xl  opacity-70 text-black text-sm font-medium py-3 drop-shadow-md col-span-1 bg-slate-100  opacity-70 border-2 hover:border-theme-100 active:opacity-100 active:grayscale-0"><FontAwesomeIcon icon={faCirclePlus}/> Custom Donation </button>

					<button id="cl_btn" onClick = {handleClearClick} className="rounded-2xl opacity-70  text-black text-sm font-medium py-3 drop-shadow-md col-span-1 bg-slate-100  opacity-70 border-2  hover:border-theme-100 active:opacity-100 bg-slate-100 active:drop-shadow-sm"><FontAwesomeIcon icon={faTrash}/> Clear Donation</button>
				</div>
				
		</div>
	);

}

// EXPORT COMPONENT
// ========================================
export default AddDonation;