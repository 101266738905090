// --------------------
// header.js |
// --------------------
// Generic header that displays the name of the organization and the header image and icon for the donation process 
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";


// COMPONENTS
// ========================================
import HeaderImage from "./HeaderImage.jsx"

// ICON
// ========================================
import icon from '../../assets/mosque.svg'


// HEADER COMPONENT
// ========================================
function Header(params){

  	// Check size of display
  	const [ isOrgNameLong, setisOrgNameLong ] = useState(false);

	// Check size of the org title
	useEffect(() => {

		if (params.display_name.length > 30) {
			setisOrgNameLong(true)
		}
	})

	return (
		<div className = "">

			{/* Standard Mobile Version */}
			<div className = "3xl:hidden">
				<div className="3xl:hidden">			
					<HeaderImage source={params.source} server={params.server} />
				</div>
				<img id="scrollspot" className = "h-10 mt-2 mb-1 text-center mx-auto" src={icon} alt="Icon" />
				<h1 id="scrollspot" className = "text-xl text-center 3xl:text-left font-normal">Donate to</h1>
				<h1 className = {`${isOrgNameLong ? "text-xl" : "text-2xl"}  text-center font-bold mb-1`}><Link to={"/" + params.orgid}>{params.display_name}</Link></h1>
			</div>

			{/* Large Desktop Version */}
			<div className = "hidden 3xl:block 3xl:mt-3 ">
				<div className=" 3xl:flex 3xl:pt-3 ">
					<h1 className = {`3xl:pb-2 ${isOrgNameLong ? "text-xl 3xl:text-3xl" : "text-2xl 3xl:text-3xl" } ml-6 `}>Donate to</h1>
					<img className = "h-9 ml-2" src={icon} alt="Icon" />
				</div>
				<h1 className = {`3xl:mb-1 ${isOrgNameLong ? "text-xl 3xl:text-3xl" : "text-2xl 3xl:text-3xl" } font-bold mb-1 pt-1 ml-6`}><Link to={"/" + params.orgid}>{params.display_name}</Link></h1>
			</div>

		</div>
	)
}


// Export Component
export default Header;