// --------------------
// currentDonation.js |
// --------------------
// Sub-component used to control the initial display of the donation amount. It also activates / de-activates the checkout
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect}  from 'react'
import { useLocation, useNavigate } from "react-router-dom";

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

// Luxon
const { DateTime } = require("luxon");

// CURRENT DONATION COMPONENT
// ========================================
function CurrentDonation(params){

	// State Variables
	const [checkoutEnabled, setCheckoutEnabled] = useState(false);
	const [suggestedEnabled, setSuggestedEnabled] = useState(false);
	const [endDate, setEndDate]= useState("");
	const [dateDisplay, setDateDisplay] = useState("");

	// Router Navigation
 	let location = useLocation();
	let navigate = useNavigate();

	// Set the initial value of the Current Donation
	useEffect(() => {
		if(params.cause.isFixed) {
			params.setCurrentDonation(params.cause.fixedValue);
		}		
	}, [])

	useEffect(() => {

		const now = DateTime.now();
		
		if(!isNaN(params.subscriptionCount)){

			if (params.subscriptionFrequency == "day"){
				setEndDate(now.plus({days: params.subscriptionCount}))
			}
			else if (params.subscriptionFrequency == "week"){
				setEndDate(now.plus({weeks: params.subscriptionCount}))
			}
			else if (params.subscriptionFrequency == "month"){
				setEndDate(now.plus({months: params.subscriptionCount}))
			}
			else if (params.subscriptionFrequency == "year"){
				setEndDate(now.plus({years: params.subscriptionCount}))
			}
			else {
				setEndDate(now);
			}

			setDateDisplay("through " + endDate.toLocaleString(DateTime.DATE_MED));
		}

		else {
			setEndDate("");
			setDateDisplay(" Until Canceled");
		}

		if(params.toggleSubscriptionCount === "false" || !params.toggleSubscriptionCount ){
			setEndDate("");
			setDateDisplay(" Until Canceled");	
		}


	}, [params.subscriptionFrequency, params.toggleSubscriptionCount, params.subscriptionCount, endDate, dateDisplay])

	// Check if the current value exceeds a set number
	useEffect(() => {

		// Check if user has met all conditions to enable the checkout button
		// Check the donation level
		if(params.currentDonation>=params.minDonation) {

			setCheckoutEnabled(true);
			
			// Check subscription 
			if(params.subscriptionEnabled || params.subscriptionEnabled == "true") {
				if(params.subscriptionFrequency == "" || params.subscriptionFrequency == "null" || params.subscriptionFrequency == undefined) {
					setCheckoutEnabled(false);
				}
				else{
					setCheckoutEnabled(true);
				}
			}

			// Check if Cause Condition is met
			if(params.cause.isFixed)
			{
				if(params.currentDonation >= params.cause.fixedValue) {
					setCheckoutEnabled(true);
				}
				else {
					setCheckoutEnabled(false);
				}
			}
		}

		else {
			setCheckoutEnabled(false);
		}

		// Check if user has met all conditions to enable the suggested donation button
		// Check the donation level
		if(params.currentDonation>=params.sugDonation) {
			setSuggestedEnabled(true);;

			// Check subscription
			if(params.subscriptionEnabled || params.subscriptionEnabled == "true") {
				if(params.subscriptionFrequency == "" || params.subscriptionFrequency == "null" || params.subscriptionFrequency == undefined) {
					setSuggestedEnabled(false);
				}
				else{
					setSuggestedEnabled(true);
				}
			}
			
		}

		else {
			setSuggestedEnabled(false);
		}

	}, [params.currentDonation, params.subscriptionEnabled, params.subscriptionFrequency]);

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	    minimumFractionDigits: 0,
	});

	// Display the Current Donation Page
	return (
		<div className="px-3">

			<h2 className="text-base font-bold text-center 3xl:text-left 3xl: pl-4 3xl:text-xl mb-3"> {params.cause.title == undefined ? "Your Donation" : params.cause.title}<span className={`text-xs 3xl:text-base font-light rounded-full  text-slate-600 ${!params.subscriptionEnabled ? "hidden" : ""}`}> - Every {params.subscriptionFrequency.charAt(0).toUpperCase() + params.subscriptionFrequency.slice(1).toLowerCase()} {dateDisplay}  </span>
			
			<span className={`${params.cause.isFixed ? "hidden" : ""}`}><span className={`text-xs 3xl:text-base font-light rounded-full  text-slate-600 ${(checkoutEnabled || params.subscriptionEnabled) ? "hidden" : ""}`}> | Min: ${params.minDonation} </span> <span className={`text-xs 3xl:text-base font-light py-1 rounded-full text-slate-600 ${(suggestedEnabled ||  params.subscriptionEnabled) ? "hidden" : ""}`}>| Suggested: ${params.sugDonation} </span></span><span className={`${params.cause.isFixed ? "" : "hidden"}`}><span className={`text-xs 3xl:text-base font-light rounded-full  text-slate-600`}> | Fee: ${params.cause.fixedValue} </span></span></h2>
			
			<div className="flex">
				<div tabIndex={0} role="button" onClick={() => {
					navigate("/" + params.orgID + "/Custom-Donation/" + (params.hasCause ? "?cause=" + params.cause.pathname : "")); 
				}}
				className="text-center mx-auto box-border rounded-full bg-slate-50 border-slate-900 border-9 w-5/12 3xl:w-1/2 shadow-inner">
					<p className="my-1 3xl:my-3 font-extrabold text-3xl lg:text-3xl py-1">{formatter.format(params.currentDonation)} </p>
				</div>
				<button 
					// component ={Link} to="/Add-On-Donation"
          			onClick={() => { 
          				navigate("/" + params.orgID + "/Help-Further/" + (params.hasCause ? "?cause=" + params.cause.pathname : "")); 
          			}}
					id="chk_btn" className="w-1/2 3xl:w-2/5  rounded-full disabled:bg-gray-400 disabled:opacity-70 text-white opacity-100 bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base 3xl:text-xl mx-auto text-center px-8  drop-shadow-xl" disabled={!checkoutEnabled}>
					<FontAwesomeIcon icon={faCircleCheck}/> Checkout 
				</button>	


			</div>
		</div>
	)		
}

// EXPORT COMPONENT
// ========================================
export default CurrentDonation;