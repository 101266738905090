// --------------------
// connection.js       |
// --------------------
// File used to separate out the various deployment versions 
// =========================================================================================================================

// URL Parser
var Url = require('url-parse');
var server_url = "";

// URL for Deployment
const getServer = () => {
  
  // Server URL (for React Testing)
  if (process.env.REACT_APP_DEPLOYMENT == "React") {
    console.log("React")
    server_url = "http://localhost:" + process.env.REACT_APP_SERVER_PORT;
    console.log(server_url);
  }

  // Server URL for Express Testing
  else if (process.env.REACT_APP_DEPLOYMENT == "Express") {
    console.log("Express")
    server_url = "http://localhost:" + process.env.REACT_APP_SERVER_PORT;
    console.log(server_url);
  }

  // Server URL for Heroku Deployment (Defualt)
  else {
    const port = window.location.port ? ":" + window.location.port : ""
    server_url = "https://" + window.location.hostname + port;
  }

  return(server_url)

};

// Export
exports.getServer = getServer;