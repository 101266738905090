// --------------
// orgWrapper.js |
// --------------
// Serves as the first-wrapper for the application once an organization is specified. Core responsibility is to retrieve the org data 
// from the server, use the data to create the org skin, and update the session with the associated org id.  
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import {useParams, useSearchParams, useNavigate, Navigate, Outlet, useOutletContext} from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Fireworks from "../sub-components/fireworks.js"


// Determine Ramadan Calendar
var moment = require('moment-umm-al-qura');
moment().format('iYYYY/iM/iD');

var RamadanStartDate = moment('1444 9 1', 'iYYYY iM iD'); // Parse a Hijri date.
var RamadanEndDate = moment('1444 9 1', 'iYYYY iM iD').endOf("iMonth");

var RamadanStartDateGregorian = RamadanStartDate.format('YYYY-M-D HH:mm:ss');
var RamadanEndDateGregorian = RamadanEndDate.format('YYYY-M-D HH:mm:ss');

var daysUntilEndOfRamadan = moment(RamadanEndDateGregorian).diff(moment(), 'days');

// COMPONENT
// ========================================
function OrgWrapper() {

	// Initial State Variables 
	// currentDonation is used to hold the donataion value
	// orgID: ID of the organization for purpose of retrieving data from the db
	// orgData: Retrieved data associated with the organization
	// isLoading: Boolean for controlling whether the loader displays.
	// successfulFetch: Boolean for indicating whether the server was able to retrieve organization data   
	// serverURL: The location of the server for API requests 
	// stripeExit and setStripeExit: Used to handle situations where a user exits the stripe checkout page
	// donationComplete and setDonationComplete: Used by donationSuccessful to trigger the org retrieval
	// dbsClicked: Donation buttons clicked 
	// goalFeatureEnabled: Enable the goal feature in displays
	// checkoutURL: Lays out the checkout url -- allows us to add on tags like goal featured

	const [currentDonation, setCurrentDonation] = useState(0);
	const [orgID, setOrgID] = useState("");
	const [orgData, setOrgdata] = useState({});
	const [successfulFetch, setSuccessfulFetch ] = useState(false);
	const [stripeExit, setStripeExit ] = useState(false);
	const [donationComplete, setDonationComplete] = useState(false);
  	const [searchParams, setSearchParams] = useSearchParams();
	const [dbsClicked, setdbsClicked] = useState([]);
	const [donationCauses, setDonationCauses] = useState([]);
	const [specifiedCause, setSpecifiedCause] = useState({})
	const [subscriptionDonation, setSubscriptionDonation] = useState(false);
	const [subscriptionFrequency, setSubscriptionFrequency] = useState("week");
    const [toggleSubscriptionCount, setToggleSubscriptionCount] = useState(false);
	const [subscriptionCount, setSubscriptionCount] = useState("6");
	const [phoneCapture, setPhoneCapture] = useState(false);
	const [fomoActive, setFomoActive] = useState(false);
    const [isHolidayActive, setIsHolidayActive ] = useState(false);
    const [activeHoliday, setActiveHoliday ] = useState({});
	const [studentNames, setStudentNames] = useState([]);
	const [currency, setCurrency] = useState("USD");
	const [schoolEnrollmentActive, setSchoolEnrollmentActive] = useState(false)
	const [schoolName, setSchoolName] = useState("Islamic School")
	const [schoolOneTimeDonation, setSchoolOneTimeDonation] = useState([]);
	const [schoolMonthlyDonation, setSchoolMonthlyDonation] = useState([]);

	// Context Data for tracking the session and server interactions 
	const outletContext = useOutletContext();
  	const sessionID = outletContext.sessionID;
  	const setSessionID = outletContext.setSessionID;
  	const serverURL = outletContext.serverURL;
  	const terminalMode =  outletContext.terminalMode;
  	const terminalReader = outletContext.terminalReader;
  	const terminalLive = outletContext.terminalLive;
	const isLoading = outletContext.isLoading;
	const setIsLoading = outletContext.setIsLoading;
	const backgroundImage = outletContext.backgroundImage;
	const setBackgroundImage = outletContext.setBackgroundImage;


	// outletData variable for passing along org data, sessionID, server URL, and load status to other components
	var outletData = {
		currentDonation: currentDonation,
		setCurrentDonation: setCurrentDonation,
		sessionID: sessionID,
		serverURL: serverURL,
		orgID: orgID,
		orgData: orgData,
		isLoading: isLoading,
		setIsLoading, setIsLoading,
		successfulFetch: successfulFetch,
		stripeExit: stripeExit,
		setStripeExit: setStripeExit,
		donationComplete: donationComplete,
		setDonationComplete: setDonationComplete,
		dbsClicked: dbsClicked,
		setdbsClicked: setdbsClicked,
		donationCauses: donationCauses,
		setDonationCauses: setDonationCauses,
		specifiedCause: specifiedCause,
		setSpecifiedCause: setSpecifiedCause,
		subscriptionDonation: subscriptionDonation,
		setSubscriptionDonation: setSubscriptionDonation,
		subscriptionFrequency: subscriptionFrequency,
		setSubscriptionFrequency: setSubscriptionFrequency,
		toggleSubscriptionCount: toggleSubscriptionCount,
		setToggleSubscriptionCount: setToggleSubscriptionCount,
		subscriptionCount: subscriptionCount,
		setSubscriptionCount: setSubscriptionCount,
		terminalMode: terminalMode,
		terminalReader: terminalReader,
		terminalLive: terminalLive,
		backgroundImage: backgroundImage,
		setBackgroundImage: setBackgroundImage,
		isHolidayActive: isHolidayActive,
		setIsHolidayActive: setIsHolidayActive,
		phoneCapture:	phoneCapture,
		setPhoneCapture: setPhoneCapture,
		studentNames: studentNames,
		setStudentNames: setStudentNames,
		currency: currency,
		setCurrency: setCurrency,
		schoolName: schoolName,
		schoolEnrollmentActive: schoolEnrollmentActive,
		schoolOneTimeDonation: schoolOneTimeDonation,
		schoolMonthlyDonation: schoolMonthlyDonation,

	}

	// Grab URL parameters (to pull the orgID)
  	var params = useParams();

	// Check if there exists a previous curent value (i.e. from a redirected checkout attempt)
	let navigate = useNavigate();
	var prevCurrentValue = searchParams.get('prev');
	var prevSessionValue = searchParams.get("as");
	var prevCauseValue = searchParams.get("cause");
	var prevSubscription = searchParams.get("recurring");
	var prevFrequency = searchParams.get("frequency");
	var prevDonationCount = searchParams.get("donationcount");

  	// Side Effect for Retrieving Organization Data. 
	useEffect(() => {

		// Set the OrgID
		setOrgID(params.orgID.replace(/[^\w\s\']|_/g, ""))

		// Build an Org API Endpoint
	 	var orgurl = serverURL + '/api/account/?orgid=' + params.orgID.replace(/[^\w\s\']|_/g, "");

	 	// Retrieve data associated with the org
	 	const fetchData = async() => {
	 		try {
		 		const result = await axios(orgurl); 

	 			setOrgdata(result.data);
	 			setIsLoading(false);	

		 		// Confirm that data was retrieved
		 		if(typeof result.data.account_id != "undefined"){
					setSuccessfulFetch(true);
					setBackgroundImage(result.data.account_theme.wallpaper_image);

		 		}

		 		// If fail to retrieve org setSuccessfulFetch to false and redirect to page-not-found
		 		else{
		 			setSuccessfulFetch(false);
					navigate( { pathname: "/Page-Not-Found"})
		 		}	
	 		}
	 		catch(err){
	 		}

	 	};

	 	// Fetch the data the moment the serverURL is ready
	 	if(serverURL != "" ){
		 	fetchData();
	 	}

	}, [sessionID, donationComplete, stripeExit]);

	// Evaluate whether School Enrollment should be displayed (and if so -- display the correct program name)
	useEffect(() => {

		// Check if feature is enabled 
		if(orgData.account_features?.islamic_school ?? false){
			
			// Retrieve School Information and Evaluate whether enrollment window is active
			var schoolurl = serverURL + '/api/schools/?orgid=' + orgID

			const fetchSchoolData = async() => {
				try {
					const result = await axios(schoolurl);
					// console.log(result);

					// Check if current date falls between enrollment start date and enrollment end date
					var currentDate = new Date()
					var enrollmentStartDate = new Date(result.data.school.enrollment_start_date)
					var enrollmentEndDate = new Date(result.data.school.enrollment_end_date)
					var isEnrollmentPeriod = currentDate >= enrollmentStartDate && currentDate <= enrollmentEndDate;

					if(isEnrollmentPeriod){
						setSchoolEnrollmentActive(true);
						setSchoolName(result.data.school.school_name);
						setSchoolOneTimeDonation(result.data.school.fees.one_time_price);
						setSchoolMonthlyDonation(result.data.school.fees.payment_plan_price);
					}
					else{
						setSchoolEnrollmentActive
					}
				} catch(err) {
					console.log(err)
				}
			}

			fetchSchoolData();
		}

		else {
			setSchoolEnrollmentActive(false);
		}

	}, [orgData])

	// Side Effect for Updating the Session in the DB
	useEffect(() => {

		// Build an update session URL
	 	var sessionUpdateOrg = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.org_id&updatevalue=" + orgID;
	 	var sessionUpdateStart = serverURL + '/sessions/update/?sessionid=' + sessionID + "&updateparameter=app_data.start_times.begin_page&updatevalue=" + Date.now();

	 	// Update Function
	 	const updateSession = async(url) => {
	 		const result = await axios(url);
	 	};


	 	// Run the Update functions once a sessionID exists
	 	if(sessionID != "") {

		 	// Run the Axios API calls
		 	updateSession(sessionUpdateOrg);	
		 	updateSession(sessionUpdateStart);	 		

	 	}


	}, [sessionID])


	// Handle situations where a user is bounced back to this page and we need to re-render the previous value
	useEffect(() => {
		
		// If there is a new previous current value change the current value stored. Then renavigate to remove it from the url
		if(parseFloat(prevCurrentValue)>0 && parseFloat(prevCurrentValue) != currentDonation) {
		
			// Set the OrgID
			setOrgID(params.orgID)

			// Signal that we've exited the stripe checkout
			setStripeExit(true);

			// Set Current Donation Value to Previous Value 
			setCurrentDonation(parseFloat(prevCurrentValue));	

			// Set the Context Value of Session ID 
			setSessionID(prevSessionValue);

			// If there is a previous subscription value set the subscription donation to true
			if(prevSubscription == "true"){
				setSubscriptionDonation(true);
				setSubscriptionFrequency(prevFrequency)
			}

			// If previous session held a subscription count then re-display it 
			if(parseInt(prevDonationCount) >0){
				setToggleSubscriptionCount(true);
				setSubscriptionCount(prevDonationCount);
			}

			// Update Session to Capture the Exit 
		 	var sessionUpdateURL = serverURL + '/sessions/update/?sessionid=' + prevSessionValue + "&updateparameter=stripe_data.exited_checkout&updatevalue=true";

		 	// Function for Updating the Session
		 	const updateData = async() => {
		 		const result = await axios(sessionUpdateURL);
		 	};

		 	// Run the Axios API Call and Track Whether Successful 
		 	updateData();			

		 	// Clean Up URL 
			navigate( { pathname: "Set-Donation/"+prevCauseValue}, {replace: true})

		}	
	}, [searchParams])

	// Side effect for setting the page title
	useEffect(() => {
 		if(successfulFetch){

			document.title = "GiveMasjid | " + orgData.account_info.display_name;
		}
	}, [orgData])



	// Render Function  
	return(

		// Specify that the wrapper should match the full height of the page. 
		<div className = "">

			{/*Helmet Script for FOMO Handling*/}

			{/*Pass the outlet context with the sessionID and serverURL */}
			<Outlet context={outletData}/>
			{/* <Fireworks /> */}


		</div>
	);	
}


// EXPORT COMPONENT
// ========================================
export default OrgWrapper;