// --------------------
// headerImage.js |
// --------------------
// Generic header that holds the large scale header image to be used in various pages.
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React from 'react'

// ICONS
// ========================================
import headerImage1 from '../../assets/Header_1.png'
import headerImage2 from '../../assets/Header_2.png'
import headerImage3 from '../../assets/Header_3.png'

// Set Header Image
var headerImage = headerImage2

// HEADERIMAGE COMPONENT
// ========================================
function HeaderImage(params){
	return (
		<div className = "3xl:hidden">
			<img className = "text-center mx-auto xl:rounded-t-3xl " src={params.server + "/images/" +  params.source} />
		</div>
	)
}


// Export Component
export default HeaderImage;