// --------------------
// bannerImage.js |
// --------------------
// Generic header that holds the large scale banner image to be used in various pages.
// =========================================================================================================================

// DEPENDENCIES
// ========================================
import React from 'react'

// HEADER COMPONENT
// ========================================
function BannerImage(params){
	return (
		<div className = "">
			<img className = "mx-auto w-max xl:rounded-t-3xl 3xl:hidden" src={params.server + "/images/" +  params.source} />
		</div>
	)
}


// Export Component
export default BannerImage;